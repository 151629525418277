import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import config from '../config';
import setSavedCookieAction from '../redux/actions/global_actions';
import { parseCreateCookie } from '../redux/queries/global_queries';

function useTappShareCookie() {
  const [cookieId, setCookieId] = useState(null);
  const [cookie, setCookie] = useCookies([config.COOKIE_ID]);
  const { savedCookie } = useSelector((state) => state.globalState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedCookie !== null && typeof savedCookie !== 'undefined') {
      setCookieId(savedCookie);
    } else if (
      cookie[config.COOKIE_ID] !== null &&
      cookie[config.COOKIE_ID] !== 'null' &&
      typeof cookie[config.COOKIE_ID] !== 'undefined'
    ) {
      dispatch(setSavedCookieAction({ cookie: cookie[config.COOKIE_ID] }));
      setCookieId(cookie[config.COOKIE_ID]);
    } else {
      // eslint-disable-next-line
      async function createNewCookie() {
        const id = await parseCreateCookie();
        if (!id) {
          return null;
        }
        dispatch(setSavedCookieAction({ cookie: id }));
        setCookie(config.COOKIE_ID, id, {
          path: '/',
          sameSite: 'strict',
          expires: new Date('Fri, 31 Dec 9999 23:59:59 GMT'),
        });
        setCookieId(id);
      }
      createNewCookie();
    }
  }, []);

  return cookieId;
}

export default useTappShareCookie;
