import * as types from '../constants/sample_constants';

export const setSampleCampaign = ({ campaign }) => ({
  type: types.SET_SAMPLE_CAMPAIGN,
  payload: {
    campaign,
  },
});

export const getSampleCampaign = ({ id }) => ({
  type: types.GET_SAMPLE_CAMPAIGN,
  payload: { id },
});
