import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Colors from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const NotFoundDesktop = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Typography>Not Found Desktop</Typography>
      </div>
    </div>
  );
};

export default NotFoundDesktop;
