import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Moment from 'moment';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import SENotFound from '../../components/mobile/NotFound';
import SEExpired from '../../components/mobile/Expired';
import SETerminated from '../../components/mobile/Terminated';
import BlueIcon from '../../assets/images/icon_blue.png';
import {
  InfoCard,
  ImageCard,
  VideoCard,
  PremiumButton,
  Social,
  FormCard,
  PollCard,
  ImageCarousel,
  MailChimpCard,
} from '../../components/mobile/Premium';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  content: {
    flexGrow: 1,
    overflow: 'scroll',
    paddingTop: '80px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  row: {
    marginBottom: 16,
    width: '100%',
  },
}));

const PremiumMobile = ({
  campaign,
  appBar,
  ogp,
  handleConversions,
  couponId,
  cookieId,
}) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowOne, setRowOne] = useState([]);
  const [rowTwo, setRowTwo] = useState([]);
  const [rowThree, setRowThree] = useState([]);
  const [rowFour, setRowFour] = useState([]);
  const [rowFive, setRowFive] = useState([]);
  const [businessName, setBusinessName] = useState('');

  useEffect(() => {
    setRowThree([]);
    setRowFour([]);
    setRowFive([]);
    setRowOne([]);
    setRowTwo([]);
    if (campaign) {
      if (campaign.shouldShowBusinessName && appBar.title) {
        setBusinessName(appBar.title);
      } else {
        setBusinessName('');
      }
      if (campaign.rowOne !== null && typeof campaign.rowOne !== 'undefined') {
        setRowOne(campaign.rowOne);
      }
      if (campaign.rowTwo !== null && typeof campaign.rowTwo !== 'undefined') {
        setRowTwo(campaign.rowTwo);
      }
      if (
        campaign.rowThree !== null &&
        typeof campaign.rowThree !== 'undefined'
      ) {
        setRowThree(campaign.rowThree);
      }
      if (
        campaign.rowFour !== null &&
        typeof campaign.rowFour !== 'undefined'
      ) {
        setRowFour(campaign.rowFour);
      }
      if (
        campaign.rowFive !== null &&
        typeof campaign.rowFive !== 'undefined'
      ) {
        setRowFive(campaign.rowFive);
      }
    }
  }, [campaign]);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getListItem = (item, row) => {
    switch (item.type) {
      case 'info':
        return (
          <InfoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
            title={ogp.title}
            description={ogp.description}
          />
        );
      case 'image':
        return (
          <ImageCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'video':
        return (
          <VideoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'button':
        return (
          <PremiumButton
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
            title={ogp.title}
            description={ogp.description}
          />
        );
      case 'social':
        return (
          <Social
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'form':
        return (
          <div>
            {cookieId && (
              <FormCard
                key={row}
                data={item.info}
                handleConversions={(_data) => handleConversions(_data, row)}
                couponId={couponId}
                cookieId={cookieId}
                row={row}
              />
            )}
          </div>
        );
      case 'poll':
        return (
          <div>
            {cookieId && (
              <PollCard
                key={row}
                data={item.info}
                handleConversions={(_data) => handleConversions(_data, row)}
                couponId={couponId}
                cookieId={cookieId}
                row={row}
              />
            )}
          </div>
        );
      case 'carousel':
        return (
          <ImageCarousel
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'mailchimp':
        return (
          <MailChimpCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      default:
        return <div />;
    }
  };

  const Content = () => {
    if (!campaign) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare"
              photoUrl={BlueIcon}
            />
          </div>
          <SENotFound message="Campaign Not Found" />
        </div>
      );
    }
    if (campaign.terminated) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={businessName}
              photoUrl={appBar.image}
              redirectUrl={appBar.url}
            />
          </div>
          <SETerminated message={campaign.terminationReason} />
        </div>
      );
    }
    if (Moment().isAfter(Moment(campaign.endDate))) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={appBar?.title}
              photoUrl={appBar?.image}
              redirectUrl={appBar.url}
            />
          </div>
          <SEExpired
            name={appBar?.title}
            logo={appBar?.image}
            url={appBar?.url}
          />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name={businessName}
            photoUrl={appBar.image}
            redirectUrl={appBar.url}
          />
        </div>
        <div className={classes.content}>
          {rowOne !== null && typeof rowOne !== 'undefined' ? (
            <div className={classes.row} style={{ marginTop: 16 }}>
              {getListItem(rowOne, 1)}
            </div>
          ) : null}

          {rowTwo !== null && typeof rowTwo !== 'undefined' ? (
            <div className={classes.row} style={{ marginTop: 16 }}>
              {getListItem(rowTwo, 2)}
            </div>
          ) : null}

          {rowThree !== null && typeof rowThree !== 'undefined' ? (
            <div className={classes.row} style={{ marginTop: 16 }}>
              {getListItem(rowThree, 3)}
            </div>
          ) : null}

          {rowFour !== null && typeof rowFour !== 'undefined' ? (
            <div className={classes.row} style={{ marginTop: 16 }}>
              {getListItem(rowFour, 4)}
            </div>
          ) : null}

          {rowFive !== null && typeof rowFive !== 'undefined' ? (
            <div className={classes.row} style={{ marginTop: 16 }}>
              {getListItem(rowFive, 5)}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Content />
      <SEDrawer
        open={openDrawer}
        handleClose={handleDrawerClose}
        conditions={campaign ? campaign.conditions : null}
      />
    </div>
  );
};

PremiumMobile.propTypes = {
  campaign: PropTypes.object.isRequired,
  couponId: PropTypes.string.isRequired,
  cookieId: PropTypes.string,
  ogp: PropTypes.object.isRequired,
  appBar: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

PremiumMobile.defaultProps = {
  cookieId: null,
};

export default PremiumMobile;
