import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import BlueIcon from '../../assets/images/icon_blue.png';
import MissingIcon from '../../assets/images/bad-link.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    marginBottom: 24,
  },
}));

const NotFoundMobile = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name="TappShare"
            photoUrl={BlueIcon}
          />
        </div>
        <div className={classes.content}>
          <img className={classes.image} src={MissingIcon} alt="Expired" />
          <Typography variant="h6">Whoops! Something went wrong.</Typography>
          <Typography variant="subtitle2" style={{ marginTop: 24 }}>
            It looks like that link does not exist
          </Typography>
        </div>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

export default NotFoundMobile;
