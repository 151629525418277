import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/desktop/AppBar';
import SEDrawer from '../../components/desktop/Drawer';
import SENotFound from '../../components/desktop/NotFound';
import BlueIcon from '../../assets/images/icon_blue.png';
import {
  InfoCard,
  ImageCard,
  VideoCard,
  PremiumButton,
  ImageCarousel,
} from '../../components/desktop/Premium';
import Clock from '../../components/desktop/Clock';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100vh',
    paddingTop: '80px',
    display: 'flex',
  },
  filler: {
    width: '35%',
    borderRightStyle: 'solid',
    borderRightWidth: 'thin',
    borderRightColor: '#E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
  },
  row: {
    marginTop: 48,
    width: '100%',
  },
}));

const SampleDesktop = ({ data, handleConversions }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowOne, setRowOne] = useState([]);
  const [rowTwo, setRowTwo] = useState([]);

  useEffect(() => {
    if (data !== null && typeof data !== 'undefined') {
      if (data.campaign !== null && typeof data.campaign !== 'undefined') {
        if (
          data.campaign.rowOne !== null &&
          typeof data.campaign.rowOne !== 'undefined'
        ) {
          setRowOne(data.campaign.rowOne);
        }
        if (
          data.campaign.rowTwo !== null &&
          typeof data.campaign.rowTwo !== 'undefined'
        ) {
          setRowTwo(data.campaign.rowTwo);
        }
      }
    }
  }, [data]);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getListItem = (item, row) => {
    switch (item.type) {
      case 'info':
        return (
          <InfoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'image':
        return (
          <ImageCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'video':
        return (
          <VideoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'button':
        return (
          <PremiumButton
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'carousel':
        return (
          <ImageCarousel
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      {data === null || typeof data === 'undefined' ? (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare"
              photoUrl={BlueIcon}
            />
          </div>
          <SENotFound message="Campaign Not Found" />
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare Sample Campaign"
              photoUrl={BlueIcon}
            />
          </div>
          <div className={classes.container}>
            <div className={classes.filler}>
              <Clock />
            </div>
            <div className={classes.content}>
              {rowOne !== null && typeof rowOne !== 'undefined' ? (
                <div className={classes.row}>{getListItem(rowOne, 1)}</div>
              ) : null}
              {rowTwo !== null && typeof rowTwo !== 'undefined' ? (
                <div className={classes.row}>{getListItem(rowTwo, 2)}</div>
              ) : null}
              <div style={{ height: 48 }} />
            </div>
          </div>
        </div>
      )}
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

SampleDesktop.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default SampleDesktop;
