import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SECTAButton from '../CTAButton';
import SEEventBottomSheet from '../EventBottomSheet';
import ShareSheet from '../ShareSheet';

const useStyles = makeStyles(() => ({
  root: {},
}));

function StandardAction({ type, action, onConversion, title, description }) {
  const classes = useStyles();
  const [cta, setCta] = useState('');
  const [eventOpen, setEventOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [shareOpen, setShareOpen] = useState(false);

  useEffect(() => {
    switch (type) {
      case 'REDIRECT':
        setCta(action.cta);
        break;
      case 'EVENT':
        setCta('Add to Calendar');
        setEventDetail({
          startTime: action.startDate,
          endTime: action.endDate,
          location: action.address,
          title,
          description,
        });
        break;
      case 'SHARE':
        setCta('Share');
        break;
      default:
        break;
    }
  }, [type, action]);

  const handleRedirectLink = () => {
    onConversion();
    const win = window.open(action.link, '_blank');
    win.focus();
  };

  const handleClick = () => {
    onConversion();
    switch (type) {
      case 'REDIRECT':
        handleRedirectLink();
        break;
      case 'EVENT':
        setEventOpen(true);
        break;
      case 'SHARE':
        setShareOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseEvent = () => {
    setEventOpen(false);
  };

  const handleCloseShare = () => {
    setShareOpen(false);
  };

  return (
    <div className={classes.root}>
      <SECTAButton text={cta} handleClick={() => handleClick()} />
      <SEEventBottomSheet
        open={eventOpen}
        handleClose={handleCloseEvent}
        event={eventDetail}
      />
      <ShareSheet
        open={shareOpen}
        handleClose={handleCloseShare}
        link={window.location.href}
        title={title}
        details={description}
      />
    </div>
  );
}

StandardAction.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  onConversion: PropTypes.func.isRequired,
};

export default StandardAction;
