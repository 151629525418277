import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Colors from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
  },
  card: {
    padding: 16,
  },
  blueCard: {
    padding: 16,
    backgroundColor: Colors.primary,
  },
  textField: {
    marginTop: 10,
    backgroundColor: Colors.white,
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary,
    },
  },
  button: {
    borderRadius: 25,
    height: 34,
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonText: {
    fontSize: 10,
    color: Colors.white,
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MailChimpCard = ({ data, handleConversions }) => {
  const classes = useStyles();
  const [finished, setFinished] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleTextChange = (e) => {
    setEmail(e.target.value);
    if (emailError) {
      setEmailError(false);
    }
  };

  const handleSubmit = () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setFinished(true);
      handleConversions({
        type: 'mailchimp',
        email,
        listId: data.listId,
      });
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className={classes.root}>
      {finished ? (
        <Card className={classes.blueCard} style={{ borderRadius: 4 * data?.styling?.cardBorder }}>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            <Typography
              variant="h6"
              style={{ color: Colors.white, marginBottom: 16 }}
            >
              Email Submitted
            </Typography>
          </div>
        </Card>
      ) : (
        <Card className={classes.card} style={{ borderRadius: 4 * data?.styling?.cardBorder }}>
          <Typography variant="h6">
            {data.title || 'Join our email list for more offers'}
          </Typography>
          <TextField
            value={email}
            label="Email"
            className={classes.textField}
            variant="outlined"
            size="small"
            dense
            fullWidth
            onChange={(e) => handleTextChange(e)}
            error={emailError}
          />
          <div className={classes.buttonRow}>
            <Button
              color="primary"
              variant={data.styling?.buttonVariant}
              style={{
                borderRadius: 4 * data.styling?.buttonBorder,
                borderColor:
                  data.styling?.buttonVariant === 'outlined'
                    ? data.styling?.buttonColor
                    : '',
                backgroundColor:
                  data.styling?.buttonVariant === 'contained'
                    ? data.styling?.buttonColor
                    : '',
              }}
              className={classes.button}
              disableElevation
              onClick={handleSubmit}
            >
              <Typography className={classes.buttonText}>Submit</Typography>
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

MailChimpCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default MailChimpCard;
