import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ExpiredIcon from '../../../assets/images/expired.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 50,
    paddingRight: 50,
    textAlign: 'center',
  },
  image: {
    marginBottom: 24,
  },
  icon: {
    width: 26,
    marginLeft: 10,
  },
}));

function SEExpired({ name, url, logo }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.image} src={ExpiredIcon} alt="Expired" />
      <Typography variant="h6">Campaign Expired</Typography>
      <Typography variant="subtitle2" style={{ marginTop: 24 }}>
        This content has expired. Thank you for your interest.
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ marginTop: 18, display: 'flex', alignItems: 'center' }}
      >
        {name || ''}
        {url ? (
          <a
            href={url}
            style={{ marginLeft: 10 }}
            target="_blank"
            rel="noreferrer"
          >
            {url}
          </a>
        ) : null}
        {logo ? (
          <img className={classes.icon} src={logo} alt="logo" />
        ) : null}
      </Typography>
    </div>
  );
}

SEExpired.defaultProps = {
  name: undefined,
  url: undefined,
  logo: undefined,
};

SEExpired.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  logo: PropTypes.string,
};

export default SEExpired;
