import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Colors from '../../../constants/colors';
import useFormResults from '../../../hooks/form_results_hook';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
  },
  card: {
    padding: 16,
  },
  blueCard: {
    padding: 16,
    backgroundColor: Colors.primary,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
  },
  headerText: {
    marginLeft: 8,
    flexGrow: 1,
  },
  textField: {
    marginTop: 10,
    backgroundColor: Colors.white,
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary,
    },
  },
  button: {
    borderRadius: 25,
    height: 30,
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  buttonText: {
    fontSize: 10,
    color: Colors.white,
  },
}));

const FormCard = ({ data, handleConversions, couponId, cookieId, row }) => {
  const classes = useStyles();
  const [finished, setFinished] = useState(false);
  const [dataState, setDataState] = useState([]);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const [formData, loading] = useFormResults(
    couponId,
    cookieId,
    row,
    timestamp,
  );

  useEffect(() => {
    if (formData && formData.type === 'finished') {
      setFinished(true);
    } else {
      const results = [];
      for (let x = 0; x < data.fields.length; x++) {
        results.push('');
      }
      setDataState(results);
    }
  }, [formData]);

  const handleTextChange = (e, index) => {
    const results = [...dataState];
    results[index] = e.target.value;
    setDataState(results);
  };

  const handleSubmit = () => {
    let valid = false;
    for (let x = 0; x < dataState.length; x++) {
      if (dataState[x] !== '') {
        valid = true;
      }
    }
    if (valid) {
      handleConversions({
        type: 'form',
        results: dataState,
      });
      setTimestamp(new Date().toISOString());
    }
  };

  return (
    <div className={classes.root}>
      {loading || finished ? (
        <Card className={classes.blueCard}>
          {loading ? (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <Typography
                variant="h6"
                style={{ color: Colors.white, marginBottom: 16 }}
              >
                Loading
              </Typography>
              <CircularProgress size={65} style={{ color: Colors.white }} />
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <Typography
                variant="h6"
                style={{ color: Colors.white, marginBottom: 16 }}
              >
                Form Submitted
              </Typography>
            </div>
          )}
        </Card>
      ) : (
        <Card className={classes.card}>
          <div className={classes.headerRow}>
            <Typography variant="subtitle1" className={classes.headerText}>
              {data.title}
            </Typography>
          </div>
          {data.fields.length > 0
            ? data.fields.map((item, index) => {
                if (item === '') {
                  return <div />;
                }
                return (
                  <TextField
                    id={index}
                    value={dataState[index]}
                    label={item}
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    dense
                    fullWidth
                    onChange={(e) => handleTextChange(e, index)}
                  />
                );
              })
            : null}
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disableElevation
            onClick={handleSubmit}
          >
            <Typography className={classes.buttonText}>Submit</Typography>
          </Button>
        </Card>
      )}
    </div>
  );
};

FormCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
  couponId: PropTypes.string.isRequired,
  cookieId: PropTypes.string.isRequired,
  row: PropTypes.number.isRequired,
};

export default FormCard;
