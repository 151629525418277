import * as types from '../constants/premium_constants';

export const setPremiumCampaign = ({ campaign, ogp, appBar }) => ({
  type: types.SET_PREMIUM_CAMPAIGN,
  payload: {
    campaign,
    ogp,
    appBar,
  },
});

export const fetchCampaignStart = () => ({
  type: types.FETCH_CAMPAIGN_START,
  payload: {},
});

export const setPremiumConversions = ({ couponId, row, data, cookieId }) => ({
  type: types.SET_PREMIUM_CONVERSION,
  payload: {
    couponId,
    row,
    data,
    cookieId,
  },
});

export const setPremiumMetrics = ({ couponId, cookieId }) => ({
  type: types.SET_PREMIUM_METRICS,
  payload: {
    couponId,
    cookieId,
  },
});

export const getPremiumCampaign = ({ id }) => ({
  type: types.GET_PREMIUM_CAMPAIGN,
  payload: { id },
});

export const getPollResults = ({ couponId, cookieId, row, callback }) => ({
  type: types.GET_PREMIUM_POLL_RESULTS,
  payload: {
    couponId,
    cookieId,
    row,
    callback,
  },
});

export const getFormResults = ({ couponId, cookieId, row, callback }) => ({
  type: types.GET_PREMIUM_FORM_RESULTS,
  payload: {
    couponId,
    cookieId,
    row,
    callback,
  },
});
