import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import BlueIcon from '../../assets/images/icon_blue.png';
import Colors from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingTop: 24,
    overflow: 'scroll',
  },
}));

const PrivacyMobile = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name="TappShare"
            photoUrl={BlueIcon}
          />
        </div>
        <div className={classes.content}>
          <Typography>Privacy Page</Typography>
        </div>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

export default PrivacyMobile;
