/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GoogleIcon from '../../../assets/icons/google.svg';
import AppleIcon from '../../../assets/icons/apple.svg';
import YahooIcon from '../../../assets/icons/yahoo.svg';
import OutlookIcon from '../../../assets/icons/outlook.svg';
import HelpersClass from '../../../utils/event_builder';
import Colors from '../../../constants/colors';

const helpers = new HelpersClass();

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 20,
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: 'center',
  },
  pill: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 75,
    height: 7,
    borderRadius: 20,
    backgroundColor: '#9E9E9E',
  },
  itemBox: {
    width: '100%',
    padding: 24,
    borderRadius: 10,
    borderColor: Colors.lightGray,
    borderStyle: 'solid',
    borderWidth: 0.7,
    '&:hover': {
      borderColor: Colors.primary,
      backgroundColor: Colors.primary,
    },
  },
  header: {
    paddingLeft: 50,
    paddingRight: 50,
    textAlign: 'center',
    marginBottom: 24,
  },
  cancelButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
    marginBottom: 16,
  },
  icon: {
    width: 45,
    height: 45,
  },
}));

function SEEventBottomSheet({ event, handleClose, open }) {
  const classes = useStyles();

  const handleAddToGoogle = () => {
    const link = helpers.buildUrl(event, 'google');
    const win = window.open(link, '_blank');
    win.focus();
    handleClose();
  };

  const handleAddToApple = () => {
    const url = helpers.buildUrl(event, 'apple');
    const filename = 'download.ics';
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleClose();
  };

  const handleAddToYahoo = () => {
    const link = helpers.buildUrl(event, 'yahoo');
    const win = window.open(link, '_blank');
    win.focus();
    handleClose();
  };

  const handleAddToOutlook = () => {
    const link = helpers.buildUrl(event, 'outlook');
    const win = window.open(link, '_blank');
    win.focus();
    handleClose();
  };

  return (
    <div>
      <Drawer
        className={classes.root}
        open={open}
        onClose={handleClose}
        anchor="bottom"
      >
        <div className={classes.content}>
          <div className={classes.pill} />
          <Typography variant="h6" className={classes.header}>
            Please select a calendar to add the event to.
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <div className={classes.itemBox} onClick={handleAddToGoogle}>
                <img
                  src={GoogleIcon}
                  className={classes.icon}
                  alt="Google Icon"
                />
                <Typography variant="subtitle2">Google</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={classes.itemBox} onClick={handleAddToApple}>
                <img
                  src={AppleIcon}
                  className={classes.icon}
                  alt="Apple Icon"
                />
                <Typography variant="subtitle2">Apple</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={classes.itemBox} onClick={handleAddToYahoo}>
                <img
                  src={YahooIcon}
                  className={classes.icon}
                  alt="Yahoo Icon"
                />
                <Typography variant="subtitle2">Yahoo</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div className={classes.itemBox} onClick={handleAddToOutlook}>
                <img
                  src={OutlookIcon}
                  className={classes.icon}
                  alt="Outlook Icon"
                />
                <Typography variant="subtitle2">Outlook</Typography>
              </div>
            </Grid>
          </Grid>
          <Button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

SEEventBottomSheet.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

export default SEEventBottomSheet;
