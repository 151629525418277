import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CancelledIcon from '../../../assets/images/cancelled.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 50,
    paddingRight: 50,
    textAlign: 'center',
  },
  image: {
    marginBottom: 24,
  },
}));

function SETerminated({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={CancelledIcon} alt="Expired" />
      <Typography variant="h6">Campaign Cancelled</Typography>
      <Typography variant="subtitle2" style={{ marginTop: 24 }}>
        {message}
      </Typography>
    </div>
  );
}

SETerminated.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SETerminated;
