/* eslint-disable  */
import Parse from 'parse/node';

export const parseGetSampleCampaign = async ({ id }) => {
  try {
    return await Parse.Cloud.run('Redemption:getSampleCampaign', {
      id,
    });
  } catch (e) {
    console.error(`Parse Error fetching sample campaign: ${e}`);
    return null;
  }
};
