import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Moment from 'moment';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import StandardAction from '../../components/mobile/StandardAction';
import SEHeroImage from '../../components/mobile/HeroImage';
import StandardContent from '../../components/mobile/StandardContent';
import SEHeader from '../../components/mobile/Header';
import SENotFound from '../../components/mobile/NotFound';
import SEExpired from '../../components/mobile/Expired';
import SETerminated from '../../components/mobile/Terminated';
import BlueIcon from '../../assets/images/icon_blue.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  content: {
    flexGrow: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  stickyHeader: {
    position: 'sticky',
    alignSelf: 'flex-start',
    top: 0,
  },
}));

const StandardMobile = ({ campaign, business, handleConversions }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }, []);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const Content = () => {
    if (campaign === null || campaign === 'undefined') {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare"
              photoUrl={BlueIcon}
            />
          </div>
          <SENotFound message="Campaign Not Found" />
        </div>
      );
    }
    if (campaign.terminated) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={business.name}
              photoUrl={business.photoUrl}
            />
          </div>
          <SETerminated message={campaign.terminationReason} />
        </div>
      );
    }
    if (Moment().isAfter(Moment(campaign.endDate))) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={business.name}
              photoUrl={business.photoUrl}
            />
          </div>
          <SEExpired />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name={business.name}
            photoUrl={business.photoUrl}
          />
        </div>
        <div className={classes.content}>
          <SEHeroImage photoUrl={campaign.photoUrl} />
          <SEHeader
            title={campaign.title}
            age={campaign.age}
            endDate={campaign.endDate}
          />
          <StandardContent
            description={campaign.description}
            conditions={campaign.conditions}
          />
        </div>
        <StandardAction
          type={campaign.type}
          action={campaign.action}
          onConversion={() => handleConversions()}
          title={campaign.title}
          description={campaign.filteredDetails}
        />
      </div>
    );
  };

  return (
    <div>
      <Content />
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

StandardMobile.propTypes = {
  campaign: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default StandardMobile;
