import { all } from 'redux-saga/effects';

import businessCardSaga from './business_card_middleware';
import premiumSaga from './premium_middleware';
import sampleSaga from './sample_middleware';
import standardSaga from './standard_middleware';

export default function* rootSaga() {
  yield all([businessCardSaga(), premiumSaga(), sampleSaga(), standardSaga()]);
}
