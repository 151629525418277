import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import SENotFound from '../../components/mobile/NotFound';
import BlueIcon from '../../assets/images/icon_blue.png';
import {
  InfoCard,
  ImageCard,
  VideoCard,
  PremiumButton,
  ImageCarousel,
} from '../../components/mobile/Premium';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  content: {
    flexGrow: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  row: {
    marginBottom: 16,
    width: '100%',
  },
}));

const SampleMobile = ({ data, handleConversions }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowOne, setRowOne] = useState([]);
  const [rowTwo, setRowTwo] = useState([]);

  useEffect(() => {
    if (data !== null && typeof data !== 'undefined') {
      if (data.campaign !== null && typeof data.campaign !== 'undefined') {
        if (
          data.campaign.rowOne !== null &&
          typeof data.campaign.rowOne !== 'undefined'
        ) {
          setRowOne(data.campaign.rowOne);
        }
        if (
          data.campaign.rowTwo !== null &&
          typeof data.campaign.rowTwo !== 'undefined'
        ) {
          setRowTwo(data.campaign.rowTwo);
        }
      }
    }
  }, [data]);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getListItem = (item, row) => {
    switch (item.type) {
      case 'info':
        return (
          <InfoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'image':
        return (
          <ImageCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'video':
        return (
          <VideoCard
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'button':
        return (
          <PremiumButton
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      case 'carousel':
        return (
          <ImageCarousel
            data={item.info}
            handleConversions={(_data) => handleConversions(_data, row)}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      {data === null || typeof data === 'undefined' ? (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare"
              photoUrl={BlueIcon}
            />
          </div>
          <SENotFound message="Campaign Not Found" />
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare Sample Campaign"
              photoUrl={BlueIcon}
            />
          </div>
          <div className={classes.content}>
            {rowOne !== null && typeof rowOne !== 'undefined' ? (
              <div className={classes.row} style={{ marginTop: 16 }}>
                {getListItem(rowOne, 1)}
              </div>
            ) : null}
            {rowTwo !== null && typeof rowTwo !== 'undefined' ? (
              <div className={classes.row} style={{ marginTop: 16 }}>
                {getListItem(rowTwo, 2)}
              </div>
            ) : null}
          </div>
        </div>
      )}
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

SampleMobile.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default SampleMobile;
