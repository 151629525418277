import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import configureStore from './redux';

const { store } = configureStore(window.REDUX_DATA);

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root') || document.createElement('div'),
);

// serviceWorker.unregister();
