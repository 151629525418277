import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Colors from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
}));

const PrivacyDesktop = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography>Privacy Page</Typography>
        </div>
      </div>
    </div>
  );
};

export default PrivacyDesktop;
