import Parse from 'parse/node';

export const parseGetStandardCampaign = async ({ id }) => {
  try {
    return await Parse.Cloud.run('Redemption:getStandardCampaign', {
      id,
    });
  } catch (e) {
    console.error(`Parse Error fetching standard campaign: ${e}`);
    return null;
  }
};

export const parseSetStandardMetrics = async ({ couponId, cookieId }) => {
  try {
    return await Parse.Cloud.run('Redemption:setStandardMetrics', {
      couponId,
      cookieId,
    });
  } catch (e) {
    console.error(`Parse Error setting standard metrics: ${e}`);
    return null;
  }
};

export const parseSetStandardConversion = async ({ couponId, cookieId }) => {
  try {
    return await Parse.Cloud.run('Redemption:setStandardConversion', {
      couponId,
      cookieId,
    });
  } catch (e) {
    console.error(`Parse Error setting standard metrics: ${e}`);
    return null;
  }
};
