import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BackgroundImage from '../../../assets/images/default-background.svg';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    height: 400,
    objectFit: 'cover',
    filter: 'brightness(60%)',
    position: 'sticky',
    top: -140,
  },
  default: {
    width: '100%',
    height: 400,
    objectFit: 'cover',
    filter: 'brightness(90%)',
    position: 'sticky',
    top: -150,
  },
}));

function SEHeroImage({ photoUrl }) {
  const classes = useStyles();
  return photoUrl !== null &&
    photoUrl !== '' &&
    typeof photoUrl !== 'undefined' ? (
    <img className={classes.image} src={photoUrl} alt="Hero" />
  ) : (
    <img className={classes.default} src={BackgroundImage} alt="Background" />
  );
}

SEHeroImage.defaultProps = {
  photoUrl: '',
};

SEHeroImage.propTypes = {
  photoUrl: PropTypes.string,
};

export default SEHeroImage;
