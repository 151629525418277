import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import NotFoundIcon from '../../../assets/images/not-found.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    marginBottom: 24,
  },
}));

function SENotFound({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={NotFoundIcon} alt="Expired" />
      <Typography variant="h6">{message}</Typography>
    </div>
  );
}

SENotFound.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SENotFound;
