import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PieChart, Pie } from 'recharts';
import WhiteGraph from '../../../assets/images/graph_white.svg';
import BlueGraph from '../../../assets/images/graph_blue.svg';
import Colors from '../../../constants/colors';
import usePollResults from '../../../hooks/poll_results_hook';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 200,
    paddingRight: 200,
  },
  card: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 48,
    paddingRight: 48,
  },
  blueCard: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 48,
    paddingRight: 48,
    backgroundColor: Colors.primary,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
  },
  headerText: {
    marginLeft: 8,
    flexGrow: 1,
  },
  headerTextWhite: {
    marginLeft: 8,
    flexGrow: 1,
    color: Colors.white,
  },
  radio: {
    padding: 0,
    marginRight: 6,
  },
  radioRow: {
    marginLeft: 16,
    marginTop: 16,
  },
  buttonRow: {
    width: '100%',
    textAlign: 'center',
    marginTop: 24,
  },
  button: {
    borderRadius: 25,
    height: 35,
  },
  buttonText: {
    fontSize: 12,
    color: Colors.white,
  },
  table: {
    backgroundColor: Colors.white,
    borderRadius: 10,
  },
}));

const RADIAN = Math.PI / 180;

const PollCard = ({ data, handleConversions, couponId, cookieId, row }) => {
  const classes = useStyles();
  const [radioValue, setRadioValue] = useState('');
  const [dataState, setDataState] = useState(null);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const [pollData, loading] = usePollResults(
    couponId,
    cookieId,
    row,
    timestamp,
  );

  const createData = async (results) => {
    const d = [];
    for (let x = 0; x < results.length; x++) {
      d.push({
        name: data.answers[x],
        amt: results[x],
      });
    }
    setDataState(d);
  };

  useEffect(() => {
    if (pollData && pollData.type === 'finished') {
      createData(pollData.results);
    }
  }, [pollData]);

  const handleSelect = (e) => {
    setRadioValue(e.target.value);
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    // innerRadius,
    // index,
  }) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1);
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          x={ex + (cos >= 0 ? 1 : -1)}
          y={ey}
          textAnchor={textAnchor}
          fill={Colors.white}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  const handleSubmit = () => {
    if (radioValue !== '') {
      handleConversions({
        type: 'poll',
        answer: parseInt(radioValue, 10),
        questions: data.answers,
      });
      setTimestamp(new Date().toISOString());
    }
  };

  const getPollPercentage = (val) => {
    if (val === 0) {
      return 0;
    }
    let total = 0;
    for (let x = 0; x < dataState.length; x++) {
      total += dataState[x].amt;
    }

    return ((val / total) * 100).toFixed(0);
  };

  return (
    <div className={classes.root}>
      {loading || dataState !== null ? (
        <Card className={classes.blueCard}>
          {loading ? (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <Typography
                variant="h6"
                style={{ color: Colors.white, marginBottom: 16 }}
              >
                Loading Poll
              </Typography>
              <CircularProgress size={65} style={{ color: Colors.white }} />
            </div>
          ) : (
            <div>
              <div className={classes.headerRow} style={{ marginBottom: 10 }}>
                <img src={WhiteGraph} className={classes.icon} alt="Icon" />
                <Typography
                  variant="subtitle1"
                  className={classes.headerTextWhite}
                >
                  {data.question}
                </Typography>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <PieChart width={400} height={400}>
                    <Pie
                      dataKey="amt"
                      fill={Colors.white}
                      cx={200}
                      cy={200}
                      innerRadius={60}
                      outerRadius={70}
                      data={dataState}
                      label={renderCustomizedLabel}
                      labelLine={true}
                      paddingAngle={10}
                    />
                  </PieChart>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Answers</TableCell>
                        <TableCell align="center">%</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataState.map((info) => {
                        const result = getPollPercentage(info.amt);
                        return (
                          <TableRow key={info.name}>
                            <TableCell align="left">{info.name}</TableCell>
                            <TableCell align="center">{`${result}%`}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <Card className={classes.card}>
          <div className={classes.headerRow}>
            <img src={BlueGraph} className={classes.icon} alt="Icon" />
            <Typography variant="h6" className={classes.headerText}>
              {data.question}
            </Typography>
          </div>
          <RadioGroup value={radioValue} onChange={handleSelect}>
            {data.answers.map((answer, index) => (
              <FormControlLabel
                className={classes.radioRow}
                value={index.toString()}
                control={<Radio className={classes.radio} color="primary" />}
                label={answer}
              />
            ))}
          </RadioGroup>
          <div className={classes.buttonRow}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              disableElevation
              onClick={handleSubmit}
            >
              <Typography className={classes.buttonText}>Submit</Typography>
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

PollCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
  couponId: PropTypes.string.isRequired,
  cookieId: PropTypes.string.isRequired,
  row: PropTypes.number.isRequired,
};

export default PollCard;
