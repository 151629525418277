import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 75,
    paddingTop: 24,
  },
  description: {
    fontSize: 14,
    lineHeight: 1.5,
  },
}));

function StandardContent({ description, conditions }) {
  const classes = useStyles();
  const [descriptionState, setDescriptionState] = useState('');
  const [conditionsState, setConditionsState] = useState('');

  useEffect(() => {
    const cfg = {};
    const descriptionConverter = new QuillDeltaToHtmlConverter(
      description,
      cfg,
    );
    setDescriptionState(descriptionConverter.convert());

    if (
      typeof conditions === 'undefined' ||
      conditions === null ||
      conditions.substring(0, 3) !== '[{"'
    ) {
      setConditionsState('');
    } else {
      const conditionsConverter = new QuillDeltaToHtmlConverter(
        conditions,
        cfg,
      );
      setConditionsState(conditionsConverter.convert());
    }
  }, [description, conditions]);

  return (
    <div className={classes.root}>
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: descriptionState }}
      />
      {conditionsState !== null && conditionsState !== '' ? (
        <div>
          <Typography variant="subtitle1">Conditions</Typography>
          <div
            className={classes.conditions}
            dangerouslySetInnerHTML={{ __html: conditionsState }}
          />
        </div>
      ) : null}
    </div>
  );
}

StandardContent.defaultProps = {
  description: undefined,
  conditions: undefined,
};

StandardContent.propTypes = {
  description: PropTypes.array,
  conditions: PropTypes.array,
};

export default StandardContent;
