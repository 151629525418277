import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_PREMIUM_CAMPAIGN,
  SET_PREMIUM_METRICS,
  SET_PREMIUM_CONVERSION,
  GET_PREMIUM_POLL_RESULTS,
  GET_PREMIUM_FORM_RESULTS,
} from '../constants/premium_constants';
import {
  setPremiumCampaign,
  fetchCampaignStart,
} from '../actions/premium_actions';
import {
  parseGetPremiumCampaign,
  parseSetPremiumMetrics,
  parseSetPremiumConversion,
  parseGetPollResults,
  parseGetFormResults,
} from '../queries/premium_queries';

function* getPremiumCampaign({ payload: { id } }) {
  try {
    yield put(fetchCampaignStart());
    const results = yield call(parseGetPremiumCampaign, { id });
    if (!results) {
      throw new Error({ code: 404, message: 'Premium Campaign Not Found' });
    }
    yield put(
      setPremiumCampaign({
        campaign: results.campaign,
        ogp: results.ogp,
        appBar: results.appBar,
      }),
    );
  } catch (e) {
    console.error(`Error fetching premium campaign`);
    yield put(setPremiumCampaign({ campaign: null, ogp: null, appBar: null }));
    // TODO: Navigate to 404 Not found
  }
}

function* setPremiumMetrics({ payload: { couponId, cookieId } }) {
  try {
    if (!couponId || !cookieId) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    yield call(parseSetPremiumMetrics, { couponId, cookieId });
  } catch (e) {
    console.error(`Error setting premium metrics: ${e}`);
  }
}

function* setPremiumConversion({ payload: { couponId, cookieId, data, row } }) {
  try {
    if (!couponId || !cookieId || !data || !row) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    yield call(parseSetPremiumConversion, { couponId, cookieId, data, row });
  } catch (e) {
    console.error(`Error setting premium conversion: ${e}`);
  }
}

function* getPollResults({ payload: { couponId, cookieId, row, callback } }) {
  try {
    if (!couponId || !cookieId || !row) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    const results = yield call(parseGetPollResults, {
      couponId,
      cookieId,
      row,
    });
    callback(results);
  } catch (e) {
    console.error(`Error getting poll results: ${e}`);
    callback();
  }
}

function* getFormResults({ payload: { couponId, cookieId, row, callback } }) {
  try {
    if (!couponId || !cookieId || !row) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    const results = yield call(parseGetFormResults, {
      couponId,
      cookieId,
      row,
    });
    callback(results);
  } catch (e) {
    console.error(`Error getting form results: ${e}`);
    callback();
  }
}

function* premiumSaga() {
  yield takeLatest(GET_PREMIUM_CAMPAIGN, getPremiumCampaign);
  yield takeLatest(SET_PREMIUM_METRICS, setPremiumMetrics);
  yield takeLatest(SET_PREMIUM_CONVERSION, setPremiumConversion);
  yield takeEvery(GET_PREMIUM_POLL_RESULTS, getPollResults);
  yield takeEvery(GET_PREMIUM_FORM_RESULTS, getFormResults);
}

export default premiumSaga;
