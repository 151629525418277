import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { CircularProgress } from '@mui/material';
import PremiumDesktop from './PremiumDesktop';
import PremiumMobile from './PremiumMobile';
import Colors from '../../constants/colors';
import BlueLogo from '../../assets/images/icon_blue.png';
import {
  getPremiumCampaign,
  setPremiumMetrics,
  setPremiumConversions,
} from '../../redux/actions/premium_actions';
import useTappShareCookie from '../../hooks/cookie_hooks';
import configUrl from '../../config-url';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const Premium = ({ couponId, preview }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookie = useTappShareCookie();
  const { campaign, ogp, appBar, loading } = useSelector(
    (state) => state.premiumState,
  );

  useEffect(() => {
    dispatch(getPremiumCampaign({ id: couponId }));
  }, []);

  useEffect(() => {
    if (cookie && !preview) {
      dispatch(setPremiumMetrics({ couponId, cookieId: cookie }));
    }
  }, [cookie]);

  const handleConversionStat = (data, row) => {
    if (!preview) {
      dispatch(
        setPremiumConversions({
          couponId,
          cookieId: cookie,
          data,
          row,
        }),
      );
    }
  };

  // console.log({ ogp });
  // console.log({ loading });
  // console.log({ appBar });

  return (
    <div className={classes.root}>
      <CssBaseline />
      {loading ? (
        <CircularProgress
          sx={{ position: 'absolute', left: '50%', top: '50%' }}
        />
      ) : (
        <>
          {ogp ? (
            <Helmet>
              <title>{ogp.title}</title>
              <meta property="og:title" content={ogp.title} />
              <meta property="og:description" content={ogp.description} />
              <meta
                property="og:image"
                itemProp="image"
                content={ogp?.image?.url ?? ogp?.image}
              />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta
                property="og:image:secure_url"
                itemProp="image"
                content={ogp?.image?.url ?? ogp?.image}
              />
              <meta
                property="og:url"
                content={`${configUrl.FRONT_END_URL}/premium/${couponId}`}
              />
              <meta property="og:type" content="website" />
            </Helmet>
          ) : (
            <Helmet>
              <title>Campaign Not Found</title>
              <meta property="og:title" content="Campaign Not Found" />
              <meta
                property="og:description"
                content="Check out TappShare. Easily distribute campaigns."
              />
              <meta property="og:image" content={BlueLogo} />
            </Helmet>
          )}
          <Hidden mdUp>
            {campaign && (
              <PremiumMobile
                campaign={campaign}
                ogp={ogp}
                couponId={couponId}
                cookieId={cookie}
                appBar={appBar}
                handleConversions={handleConversionStat}
              />
            )}
          </Hidden>
          <Hidden smDown>
            {campaign && (
              <PremiumDesktop
                campaign={campaign}
                couponId={couponId}
                cookieId={cookie}
                ogp={ogp}
                appBar={appBar}
                handleConversions={handleConversionStat}
              />
            )}
          </Hidden>
        </>
      )}
    </div>
  );
};

Premium.serverFetch = getPremiumCampaign;

Premium.propTypes = {
  couponId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

Premium.defaultProps = {
  preview: false,
};

export default Premium;
