import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
  },
  container: {
    textAlign: 'center',
  },
}));

function VideoCard({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card style={{ borderRadius: 4 * data?.styling?.cardBorder }}>
        {data.type === 'youtube' ? (
          <div className={classes.container}>
            <iframe
              height={250}
              width="100%"
              src={`https://youtube.com/embed/${data.code}`}
              frameBorder="0"
              title="Video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        ) : null}

        {data.type === 'venmo' ? (
          <div>
            <iframe
              src={`https://player.vimeo.com/video/${data.code}?color=a802f5`}
              height={250}
              width="100%"
              frameBorder="0"
              title="Video"
              allow="autoplay; fullscreen"
            />
          </div>
        ) : null}
        {data.title !== null &&
        typeof data.title !== 'undefined' &&
        data.title !== '' ? (
          <Box
            width="100%"
            color={data.styling?.mainColor}
            textAlign={data.styling?.mainAlignment}
            fontSize={data.styling?.mainSize}
            fontStyle={
              data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
            }
            style={{
              textDecoration: data.styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
              padding: 8,
            }}
            fontWeight={
              data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
            }
          >
            <Typography variant="subtitle2">{data.title}</Typography>
          </Box>
        ) : null}
      </Card>
    </div>
  );
}

VideoCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VideoCard;
