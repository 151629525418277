import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_BUSINESS_CARD } from '../constants/business_card_constants';
import { setBusinessCard } from '../actions/business_card_actions';
import { parseGetBusinessCard } from '../queries/business_card_queries';

function* getBusinessCard({ payload: { id } }) {
  try {
    const card = yield call(parseGetBusinessCard, { id });
    if (!card) {
      throw new Error({ code: 404, message: 'Business Card Not Found' });
    }
    yield put(setBusinessCard({ card }));
  } catch (e) {
    console.error(`Error fetching business card`);
    yield put(setBusinessCard({ card: null }));
    // TODO: Navigate to 404 Not found
  }
}

function* businessCardSaga() {
  yield takeLatest(GET_BUSINESS_CARD, getBusinessCard);
}

export default businessCardSaga;
