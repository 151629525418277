/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import QRCode from 'qrcode.react';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/desktop/AppBar';
import SEDrawer from '../../components/desktop/Drawer';
import FacebookIcon from '../../assets/icons/facebook.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import LinkedInIcon from '../../assets/icons/linkedin.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import WhatsAppIcon from '../../assets/icons/whatsapp_icon.svg';
import TelegramIcon from '../../assets/icons/telegram_icon.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100vh',
    paddingTop: '80px',
    display: 'flex',
  },
  left: {
    width: '25%',
    minWidth: '25%',
    height: '100%',
    borderRightColor: '#CFD8DC',
    borderRightWidth: 0.5,
    borderRightStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
  },
  missingPhoto: {
    width: '100%',
    height: 250,
    backgroundColor: Colors.lightGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  personIcon: {
    width: 100,
    height: 100,
    color: Colors.primary,
  },
  profilePhoto: {
    width: '100%',
  },
  qrWrapper: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    paddingTop: 50,
    paddingRight: 50,
    paddingLeft: 50,
    paddingBottom: 50,
    flexGrow: 1,
  },
  nameFont: {
    fontSize: 48,
    fontWeight: '300',
    marginBottom: 24,
  },
  icon: {
    width: 24,
    height: 24,
    color: Colors.blueGray,
    marginRight: 16,
  },
  itemRow: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  information: {
    width: '100%',
    marginTop: 74,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  link: {
    textDecoration: 'none',
    fontSize: 16,
    color: Colors.font,
  },
  socialMedia: {
    paddingRight: 24,
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  socialIcon: {
    width: 36,
    cursor: 'pointer',
    marginBottom: 36,
  },
  saveButton: {
    width: '100%',
    color: Colors.white,
    backgroundColor: Colors.primary,
    marginTop: 24,
    padding: 12,
    borderRadius: 24,
  },
}));

const BusinessCardDesktop = ({
  cardId,
  user,
  business,
  socials,
  downloadContact,
}) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6,
      )}-${phoneNumber.substring(6, 10)}`;
    }
    return phoneNumber;
  };

  const handleSocialMediaClick = (link) => {
    const win = window.open(link, '_self');
    win.focus();
  };

  return (
    <div>
      <div className={classes.root}>
        <SEAppBar
          handleOpenDrawer={handleDrawerOpen}
          name={business.name}
          photoUrl={business.photo}
          redirectUrl={business.url}
        />
        <div className={classes.container}>
          <div className={classes.left}>
            {user.photo ? (
              <img
                className={classes.profilePhoto}
                src={user.photo}
                alt="Profile"
              />
            ) : (
              <div className={classes.missingPhoto}>
                <PersonOutlineIcon className={classes.personIcon} />
              </div>
            )}
            <div className={classes.qrWrapper}>
              <QRCode
                id="business-card-qr"
                value={`https://justforyou.fyi/businesscard/${cardId}`}
                fgColor="#424242"
                size={200}
              />
            </div>
          </div>
          <div className={classes.content}>
            <Typography className={classes.nameFont}>{user.name}</Typography>
            {user.position ? (
              <div className={classes.itemRow}>
                <BusinessIcon className={classes.icon} />
                <Typography variant="subtitle1">{user.position}</Typography>
              </div>
            ) : (
              <div />
            )}
            {user.location ? (
              <div className={classes.itemRow}>
                <RoomIcon className={classes.icon} />
                <Typography variant="subtitle1">{user.location}</Typography>
              </div>
            ) : (
              <div />
            )}
            <Grid container className={classes.information} spacing={4}>
              <Grid item md={6}>
                <Typography variant="h4" style={{ marginBottom: 24 }}>
                  Contacts
                </Typography>
                <Typography variant="caption">Primary Email</Typography>
                <div className={classes.itemRow}>
                  <EmailIcon className={classes.icon} />
                  <a href={`mailto:${user.email}`} className={classes.link}>
                    {user.email}
                  </a>
                </div>
                <Divider className={classes.divider} />
                {user.secondaryEmail ? (
                  <div>
                    <Typography variant="caption">Secondary Email</Typography>
                    <div className={classes.itemRow}>
                      <EmailIcon className={classes.icon} />
                      <a
                        href={`mailto:${user.secondaryEmail}`}
                        className={classes.link}
                      >
                        {user.secondaryEmail}
                      </a>
                    </div>
                    <Divider className={classes.divider} />
                  </div>
                ) : (
                  <div />
                )}
                {user.mobilePhone ? (
                  <div>
                    <Typography variant="caption">Mobile Phone</Typography>
                    <div className={classes.itemRow}>
                      <PhoneIcon className={classes.icon} />
                      <a
                        href={`tel:${user.mobilePhone}`}
                        className={classes.link}
                      >
                        {formatPhoneNumber(user.mobilePhone)}
                      </a>
                    </div>
                    <Divider className={classes.divider} />
                  </div>
                ) : (
                  <div />
                )}
                {user.businessPhone ? (
                  <div>
                    <Typography variant="caption">Business Phone</Typography>
                    <div className={classes.itemRow}>
                      <PhoneIcon className={classes.icon} />
                      <a
                        href={`tel:${user.businessPhone}`}
                        className={classes.link}
                      >
                        {formatPhoneNumber(user.businessPhone)}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  onClick={downloadContact}
                >
                  Save Contact
                </Button>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h4" style={{ marginBottom: 24 }}>
                  About
                </Typography>
                <Typography variant="subtitle2">
                  {user.about ? user.about : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {socials.facebook ||
          socials.twitter ||
          socials.instagram ||
          socials.telegram ||
          socials.whatsapp ||
          socials.linkedin ? (
            <div className={classes.socialMedia}>
              {socials.facebook ? (
                <img
                  className={classes.socialIcon}
                  src={FacebookIcon}
                  alt="facebook"
                  onClick={() =>
                    handleSocialMediaClick(
                      `https://facebook.com/${socials.facebook}`,
                    )
                  }
                />
              ) : (
                <div />
              )}
              {socials.twitter ? (
                <img
                  className={classes.socialIcon}
                  src={TwitterIcon}
                  alt="twitter"
                  onClick={() =>
                    handleSocialMediaClick(
                      `https://twitter.com/${socials.twitter}`,
                    )
                  }
                />
              ) : (
                <div />
              )}
              {socials.linkedin ? (
                <img
                  className={classes.socialIcon}
                  src={LinkedInIcon}
                  alt="linkedin"
                  onClick={() =>
                    handleSocialMediaClick(
                      `https://linkedin.com/in/${socials.linkedin}`,
                    )
                  }
                />
              ) : (
                <div />
              )}
              {socials.instagram ? (
                <img
                  className={classes.socialIcon}
                  src={InstagramIcon}
                  alt="instagram"
                  onClick={() =>
                    handleSocialMediaClick(
                      `https://instagram.com/${socials.instagram}`,
                    )
                  }
                />
              ) : (
                <div />
              )}
              {socials.whatsapp ? (
                <img
                  className={classes.socialIcon}
                  src={WhatsAppIcon}
                  alt="whatsapp"
                  onClick={() =>
                    handleSocialMediaClick(`https://wa.me/${socials.whatsapp}`)
                  }
                />
              ) : (
                <div />
              )}
              {socials.telegram ? (
                <img
                  className={classes.socialIcon}
                  src={TelegramIcon}
                  alt="telegram"
                  onClick={() =>
                    handleSocialMediaClick(
                      `https://telegram.me/${socials.telegram}`,
                    )
                  }
                />
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

BusinessCardDesktop.propTypes = {
  cardId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  socials: PropTypes.object,
  downloadContact: PropTypes.func.isRequired,
};

BusinessCardDesktop.defaultProps = {
  socials: {
    facebook: null,
    twitter: null,
    linkedin: null,
    instagram: null,
    telegram: null,
    whatsapp: null,
  },
};

export default BusinessCardDesktop;
