import * as types from '../constants/premium_constants';

const INITIAL_STATE = {
  campaign: null,
  ogp: null,
  appBar: null,
  loading: true,
};

const setCampaign = (state, action) => ({
  ...state,
  loading: false,
  campaign: action.payload.campaign,
  ogp: action.payload.ogp,
  appBar: action.payload.appBar,
});

function premiumReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_CAMPAIGN_START:
      return { ...state, loading: true };
    case types.SET_PREMIUM_CAMPAIGN: {
      return setCampaign(state, action);
    }
    default: {
      return state;
    }
  }
}

export default premiumReducer;
