/* eslint-disable no-nested-ternary */

// const dev = {
//   // PARSE_URL: 'https://api.justspeakeasy.com/parse',
//   // PARSE_URL: 'http://localhost:1337/parse',
//   PARSE_URL: 'https://api-dev.justspeakeasy.com/parse',
//   VCARD_URL: 'http://localhost:1337/contact/vcard',
//   // VCARD_URL: 'https://api-dev.justspeakeasy.com/contact/vcard',
//   PARSE_APP_ID: 'o0tjRPZnbwmurH37bznkfsrht9Rmdt4zPS7f4v2T',
//   // PARSE_APP_ID: 'AIzaSyBTpGEUBEDpbjRNNzmAmPxh40mWHXKvTE8',
//   // PARSE_APP_ID: 'GGoRYuDhntlY4DvTF7MFfEdQ73gGqg9YViQuuGd3',
//   COOKIE_ID: '__tappShareDevId',
//   MARKETING_URL: 'https://tappshare.io',
//   PORT: 3000,
// };


const dev = {
  PARSE_URL: 'https://api-dev.justspeakeasy.com/parse',
  VCARD_URL: 'https://api-dev.justspeakeasy.com/contact/vcard',
  PARSE_APP_ID: 'o0tjRPZnbwmurH37bznkfsrht9Rmdt4zPS7f4v2T',
  COOKIE_ID: "__tappShareDevId",
  MARKETING_URL: 'https://tappshare.io',
  PORT: 3000 
};

const staging = {
  PARSE_URL: '###########################',
  VCARD_URL: '###########################',
  PARSE_APP_ID: '############################',
  COOKIE_ID: '__tappShareStagingId',
  MARKETING_URL: 'https://justspeakeasy.com',
  PORT: 4000,
};


const prod = {
  PARSE_URL: 'https://api.justspeakeasy.com/parse',
  VCARD_URL: 'https://api.justspeakeasy.com/contact/vcard',
  PARSE_APP_ID: 'GGoRYuDhntlY4DvTF7MFfEdQ73gGqg9YViQuuGd3',
  COOKIE_ID: "__tappShareUserId",
  MARKETING_URL: 'https://justspeakeasy.com',
  PORT: 3000,
PRIVACY_URL: 'https://docs.google.com/document/d/19z4KHMKHbzhmFsTrJ6IIigZ2ODK-XpsNjAkoQMpan2I/edit#',
  TERMS_CONDITIONS_URL: 'https://www.termsfeed.com/live/f1df9e83-049e-4456-ac56-5205c8e24b06',
};

const config =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : prod;

export default {
  ...config,
};
