import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
  clock: {
    fontSize: 46,
  },
}));

function Clock() {
  const classes = useStyles();
  const [time, setTime] = useState(new Moment().format('h:mm A'));

  const getTime = () => {
    const moment = new Moment();
    setTime(moment.format('h:mm A'));
    setTimeout(() => {
      getTime();
    }, 1000);
  };

  useEffect(() => {
    getTime();
  }, []);

  return (
    <div className={classes.root}>
      <Typography>{new Moment().format('MMMM D, YYYY')}</Typography>
      <Typography className={classes.clock}>{time}</Typography>
    </div>
  );
}

export default Clock;
