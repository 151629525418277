export const GET_PREMIUM_CAMPAIGN = 'redemption/premium/GET_PREMIUM_CAMPAIGN';

export const SET_PREMIUM_CAMPAIGN = 'redemption/premium/SET_PREMIUM_CAMPAIGN';

export const FETCH_CAMPAIGN_START = 'redemption/premium/FETCH_CAMPAIGN_START';

export const SET_PREMIUM_METRICS = 'redemption/premium/SET_PREMIUM_METRICS';

export const SET_PREMIUM_CONVERSION =
  'redemption/premium/SET_PREMIUM_CONVERSION';

export const GET_PREMIUM_POLL_RESULTS =
  'redemption/premium/GET_PREMIUM_POLL_RESULTS';

export const GET_PREMIUM_FORM_RESULTS =
  'redemption/premium/GET_PREMIUM_FORM_RESULTS';
