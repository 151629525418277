import * as types from '../constants/standard_constants';

export const setStandardCampaign = ({ campaign, business }) => ({
  type: types.SET_STANDARD_CAMPAIGN,
  payload: {
    campaign,
    business,
  },
});

export const getStandardCampaign = ({ id }) => ({
  type: types.GET_STANDARD_CAMPAIGN,
  payload: { id },
});

export const setStandardConversions = ({ couponId, cookieId }) => ({
  type: types.SET_STANDARD_CONVERSION,
  payload: {
    couponId,
    cookieId,
  },
});

export const setStandardMetrics = ({ couponId, cookieId }) => ({
  type: types.SET_STANDARD_METRICS,
  payload: {
    couponId,
    cookieId,
  },
});
