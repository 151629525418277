import * as types from '../constants/business_card_constants';

const INITIAL_STATE = {
  businessCard: null,
};

const setBusinessCard = (state, action) => ({
  ...state,
  businessCard: action.payload.card,
});

function businessCardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_BUSINESS_CARD: {
      return setBusinessCard(state, action);
    }
    default: {
      return state;
    }
  }
}

export default businessCardReducer;
