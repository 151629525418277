import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Colors from '../../../constants/colors';
import MenuIcon from '../../../assets/icons/menu.svg';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: Colors.white,
    paddingTop: 5,
    paddingBottom: 5,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: Colors.font,
  },
  image: {
    height: 50,
    marginLeft: 8,
  },
}));

function SEAppBar({ photoUrl, name, handleOpenDrawer, redirectUrl }) {
  const classes = useStyles();

  const BusinessInfo = (
    <>
      {photoUrl !== null && typeof photoUrl !== 'undefined' ? (
        <img className={classes.image} src={photoUrl} alt="Location" />
      ) : null}
    </>
  );

  const formattedUrl =
    redirectUrl &&
    (redirectUrl.includes('https://') || redirectUrl.includes('http://'))
      ? redirectUrl
      : `https://${redirectUrl}`;

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {redirectUrl ? (
            <a
              href={formattedUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.anchor}
            >
              {BusinessInfo}
            </a>
          ) : (
            BusinessInfo
          )}
          <Typography variant="subtitle2" className={classes.title}>
            {name}
          </Typography>
          <IconButton aria-label="drawer" onClick={handleOpenDrawer}>
            <img src={MenuIcon} alt="Icon" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

SEAppBar.defaultProps = {
  photoUrl: '',
  redirectUrl: null,
};

SEAppBar.propTypes = {
  name: PropTypes.string.isRequired,
  handleOpenDrawer: PropTypes.func.isRequired,
  photoUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default SEAppBar;
