import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Colors from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 16,
    textAlign: 'center',
    background:
      'linear-gradient(0deg, rgba(33,33,33,0.41358550256039917) 0%, rgba(239,243,247,0) 100%)',
  },
  button: {
    width: '100%',
    height: 50,
    backgroundColor: Colors.primary,
    color: Colors.white,
    borderRadius: 50,
  },
}));

function SECTAButton({ handleClick, text }) {
  const classes = useStyles();

  return (
    <div className={classes.root} elevation={4}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClick}
      >
        {text}
      </Button>
    </div>
  );
}

SECTAButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SECTAButton;
