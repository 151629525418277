import * as types from '../constants/global_constants';

const INITIAL_STATE = {
  savedCookie: null,
};

const setCookie = (state, action) => ({
  ...state,
  savedCookie: action.data.cookie,
});

function globalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SAVED_COOKIE: {
      return setCookie(state, action);
    }
    default: {
      return state;
    }
  }
}

export default globalReducer;
