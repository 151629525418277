import * as types from '../constants/business_card_constants';

export const setBusinessCard = ({ card }) => ({
  type: types.SET_BUSINESS_CARD,
  payload: {
    card,
  },
});

export const getBusinessCard = ({ id }) => ({
  type: types.GET_BUSINESS_CARD,
  payload: { id },
});
