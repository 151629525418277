export const GET_STANDARD_CAMPAIGN =
  'redemption/businesscard/GET_STANDARD_CAMPAIGN';

export const SET_STANDARD_CAMPAIGN =
  'redemption/businesscard/SET_STANDARD_CAMPAIGN';

export const SET_STANDARD_METRICS = 'redemption/premium/SET_STANDARD_METRICS';

export const SET_STANDARD_CONVERSION =
  'redemption/premium/SET_STANDARD_CONVERSION';
