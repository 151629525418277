import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import Colors from '../../constants/colors';
import BackgroundImage from '../../assets/images/default-background.svg';
import SELogo from '../../assets/images/icon_blue.png';
import config from '../../config';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingTop: 104,
    overflow: 'scroll',
  },
  image: {
    height: 160,
  },
  logoContainer: {
    height: 160,
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    width: 150,
    objectFit: 'fit',
    marginTop: 30,
  },
  card: {
    borderRadius: 10,
  },
}));

const HomeMobile = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name="TappShare App"
            photoUrl={SELogo}
          />
        </div>
        <div className={classes.content}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.image}
              image={BackgroundImage}
              title="Create Campaigns"
            />
            <CardContent>
              <Typography variant="h6" component="h2">
                Create Campaigns
              </Typography>
              <Typography variant="body2" component="p">
                Do you want to create campaigns? Easily share content, calendar
                invites, or even coupons through all messaging channels.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                href={`${config.MARKETING_URL}/pricing/`}
                size="small"
                color="primary"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
          <div style={{ marginTop: 24 }}>
            <Card className={classes.card}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={SELogo} alt="Logo" />
              </div>
              <CardContent>
                <Typography variant="h6" component="h2">
                  What is TappShare
                </Typography>
                <Typography variant="body2" component="p">
                  TappShare is the premier digital word-of-mouth messaging
                  platform for team syndicated content.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href={config.MARKETING_URL}
                  size="small"
                  color="primary"
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

export default HomeMobile;
