import React from 'react';
import PropTypes from 'prop-types';
import { Instagram, LinkedIn, Twitter, Facebook } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
  icon: {
    width: 25,
    height: 25,
  },
}));

function Social({ data, handleConversions }) {
  const classes = useStyles();

  const handleConversion = async (type, link) => {
    await handleConversions({
      type: 'social',
      social: type,
    });
    const win = window.open(link, '_self');
    win.focus();
  };

  return (
    <div className={classes.root}>
      {data.facebook !== null &&
      typeof data.facebook !== 'undefined' &&
      data.facebook !== '' ? (
        <Paper
          elevation={2}
          className={classes.container}
          style={{
            borderRadius: data?.styling?.buttonBorder * 4,
            color: data.styling?.buttonColor,
          }}
          onClick={() => handleConversion('facebook', data.facebook)}
        >
          <Facebook />
        </Paper>
      ) : null}

      {data.twitter !== null &&
      typeof data.twitter !== 'undefined' &&
      data.twitter !== '' ? (
        <Paper
          elevation={2}
          className={classes.container}
          style={{
            borderRadius: data?.styling?.buttonBorder * 4,
            color: data.styling?.buttonColor,
          }}
          onClick={() => handleConversion('twitter', data.twitter)}
        >
          <Twitter />
        </Paper>
      ) : null}

      {data.linkedin !== null &&
      typeof data.linkedin !== 'undefined' &&
      data.linkedin !== '' ? (
        <Paper
          elevation={2}
          className={classes.container}
          style={{
            borderRadius: data?.styling?.buttonBorder * 4,
            color: data.styling?.buttonColor,
          }}
          onClick={() => handleConversion('linkedin', data.linkedin)}
        >
          <LinkedIn />
        </Paper>
      ) : null}

      {data.instagram !== null &&
      typeof data.instagram !== 'undefined' &&
      data.instagram !== '' ? (
        <Paper
          elevation={2}
          className={classes.container}
          style={{
            borderRadius: data?.styling?.buttonBorder * 4,
            color: data.styling?.buttonColor,
          }}
          onClick={() => handleConversion('instagram', data.instagram)}
        >
          <Instagram />
        </Paper>
      ) : null}
    </div>
  );
}

Social.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default Social;
