import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './middleware';

const sagaMiddleware = createSagaMiddleware();
const enhancers = [applyMiddleware(sagaMiddleware)];

const persistConfig = {
  key: 'tappShareReduxData',
  storage,
};

let persistor;
const persistedReducer = persistReducer(persistConfig, rootReducer);

// If Redux Devtools Extension is installed use it, otherwise use Redux compose
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore(preloadedState) {
  if (preloadedState) {
    const store = createStore(
      persistedReducer,
      preloadedState,
      composeEnhancers(...enhancers),
    );
    sagaMiddleware.run(rootSaga);
    persistor = persistStore(store);
    return { persistor, store };
  }
  const store = createStore(persistedReducer, composeEnhancers(...enhancers));
  sagaMiddleware.run(rootSaga);
  persistor = persistStore(store);
  return { persistor, store };
}
