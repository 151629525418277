/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import IconButton from '@material-ui/core/IconButton';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/mobile/AppBar';
import SEDrawer from '../../components/mobile/Drawer';
import FacebookIcon from '../../assets/icons/facebook.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import LinkedInIcon from '../../assets/icons/linkedin.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import WhatsAppIcon from '../../assets/icons/whatsapp_icon.svg';
import TelegramIcon from '../../assets/icons/telegram_icon.svg';
import QRCode from '../../assets/images/qr-code.png';
import QRSheet from '../../components/mobile/QRSheet';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  content: {
    flexGrow: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  missingPhoto: {
    width: '100%',
    height: 250,
    backgroundColor: Colors.lightGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  personIcon: {
    width: 74,
    height: 74,
    color: Colors.primary,
  },
  profilePhoto: {
    width: '100%',
  },
  inner: {
    paddingTop: 20,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
  },
  nameRow: {
    width: '100%',
    display: 'flex',
    marginBottom: 16,
  },
  nameText: {
    paddingTop: 2,
    paddingBottom: 2,
    margin: 0,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  icon: {
    width: 18,
    height: 18,
    color: Colors.blueGray,
    marginRight: 16,
  },
  headers: {
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 8,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  link: {
    textDecoration: 'none',
    fontSize: 16,
    color: Colors.font,
  },
  itemRow: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    width: '100%',
    color: Colors.white,
    backgroundColor: Colors.primary,
    marginTop: 24,
    padding: 12,
    borderRadius: 24,
  },
  socialMedia: {
    paddingTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  socialIcon: {
    width: 20,
    cursor: 'pointer',
    marginLeft: 4,
    marginRight: 4,
  },
  qrImage: {
    width: 36,
    height: 36,
  },
}));

const BusinessCardMobile = ({
  cardId,
  user,
  business,
  socials,
  downloadContact,
}) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showQr, setShowQr] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }, []);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleSocialMediaClick = (link) => {
    const win = window.open(link, '_self');
    win.focus();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6,
      )}-${phoneNumber.substring(6, 10)}`;
    }
    return phoneNumber;
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name={business.name}
            photoUrl={business.photo}
          />
        </div>
        <div className={classes.content}>
          {user.photo ? (
            <img
              className={classes.profilePhoto}
              src={user.photo}
              alt="Profile"
            />
          ) : (
            <div className={classes.missingPhoto}>
              <PersonOutlineIcon className={classes.personIcon} />
            </div>
          )}
          <div className={classes.inner}>
            <div className={classes.nameRow}>
              <div style={{ flexGrow: 1 }}>
                <div className={classes.nameText}>{user.firstName}</div>
                <div className={classes.nameText}>{user.lastName}</div>
              </div>
              <IconButton onClick={() => setShowQr(true)}>
                <img className={classes.qrImage} src={QRCode} alt="QR Code" />
              </IconButton>
            </div>
            {user.position ? (
              <div className={classes.itemRow}>
                <BusinessIcon className={classes.icon} />
                <Typography variant="subtitle1">{user.position}</Typography>
              </div>
            ) : (
              <div />
            )}
            {user.location ? (
              <div className={classes.itemRow}>
                <RoomIcon className={classes.icon} />
                <Typography variant="subtitle1">{user.location}</Typography>
              </div>
            ) : (
              <div />
            )}
            <Typography variant="h5" className={classes.headers}>
              About
            </Typography>
            <Typography variant="subtitle2">
              {user.about ? user.about : 'N/A'}
            </Typography>
            {socials.facebook ||
            socials.twitter ||
            socials.instagram ||
            socials.telegram ||
            socials.whatsapp ||
            socials.linkedin ? (
              <div className={classes.socialMedia}>
                {socials.facebook ? (
                  <img
                    className={classes.socialIcon}
                    src={FacebookIcon}
                    alt="facebook"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://facebook.com/${socials.facebook}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
                {socials.twitter ? (
                  <img
                    className={classes.socialIcon}
                    src={TwitterIcon}
                    alt="twitter"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://twitter.com/${socials.twitter}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
                {socials.linkedin ? (
                  <img
                    className={classes.socialIcon}
                    src={LinkedInIcon}
                    alt="linkedin"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://linkedin.com/in/${socials.linkedin}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
                {socials.instagram ? (
                  <img
                    className={classes.socialIcon}
                    src={InstagramIcon}
                    alt="instagram"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://instagram.com/${socials.instagram}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
                {socials.whatsapp ? (
                  <img
                    className={classes.socialIcon}
                    src={WhatsAppIcon}
                    alt="whatsapp"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://wa.me/${socials.whatsapp}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
                {socials.telegram ? (
                  <img
                    className={classes.socialIcon}
                    src={TelegramIcon}
                    alt="telegram"
                    onClick={() =>
                      handleSocialMediaClick(
                        `https://telegram.me/${socials.telegram}`,
                      )
                    }
                  />
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}
            <Typography variant="h5" className={classes.headers}>
              Contact
            </Typography>
            <Typography variant="caption">Primary Email</Typography>
            <div className={classes.itemRow}>
              <EmailIcon className={classes.icon} />
              <a href={`mailto:${user.email}`} className={classes.link}>
                {user.email}
              </a>
            </div>
            <Divider className={classes.divider} />
            {user.secondaryEmail ? (
              <div>
                <Typography variant="caption">Secondary Email</Typography>
                <div className={classes.itemRow}>
                  <EmailIcon className={classes.icon} />
                  <a
                    href={`mailto:${user.secondaryEmail}`}
                    className={classes.link}
                  >
                    {user.secondaryEmail}
                  </a>
                </div>
                <Divider className={classes.divider} />
              </div>
            ) : (
              <div />
            )}
            {user.mobilePhone ? (
              <div>
                <Typography variant="caption">Mobile Phone</Typography>
                <div className={classes.itemRow}>
                  <PhoneIcon className={classes.icon} />
                  <a href={`tel:${user.mobilePhone}`} className={classes.link}>
                    {formatPhoneNumber(user.mobilePhone)}
                  </a>
                </div>
                <Divider className={classes.divider} />
              </div>
            ) : (
              <div />
            )}
            {user.businessPhone ? (
              <div>
                <Typography variant="caption">Business Phone</Typography>
                <div className={classes.itemRow}>
                  <PhoneIcon className={classes.icon} />
                  <a
                    href={`tel:${user.businessPhone}`}
                    className={classes.link}
                  >
                    {formatPhoneNumber(user.businessPhone)}
                  </a>
                </div>
              </div>
            ) : (
              <div />
            )}
            <Button
              className={classes.saveButton}
              variant="contained"
              onClick={downloadContact}
            >
              Save Contact
            </Button>
          </div>
        </div>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
      <QRSheet
        open={showQr}
        handleClose={() => setShowQr(false)}
        link={`https://justforyou.fyi/businesscard/${cardId}`}
      />
    </div>
  );
};

BusinessCardMobile.propTypes = {
  cardId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  socials: PropTypes.object,
  downloadContact: PropTypes.func.isRequired,
};

BusinessCardMobile.defaultProps = {
  socials: {
    facebook: null,
    twitter: null,
    linkedin: null,
    instagram: null,
    telegram: null,
    whatsapp: null,
  },
};

export default BusinessCardMobile;
