/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { Link } from '@material-ui/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import BlueLogo from '../../../assets/images/icon_blue.png';
import config from '../../../config';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    minWidth: 400,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {},
  grow: {
    flexGrow: 1,
  },
  logo: {
    width: 100,
    marginTop: 8,
  },
  conditions: {
    marginBottom: 24,
    overflowY: 'auto',
    textAlign: 'start',
  },
}));

const SEDrawer = ({ conditions, open, handleClose }) => {
  const classes = useStyles();
  const [htmlConditions, setHtmlConditions] = useState(null);

  useEffect(() => {
    if (conditions) {
      const converter = new QuillDeltaToHtmlConverter(conditions, {});
      setHtmlConditions(converter.convert());
    }
  }, [conditions]);

  const whatis = () => {
    const win = window.open(config.MARKETING_URL, '_blank');
    win.focus();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className={classes.root}>
        {htmlConditions && (
          <div className={classes.conditions}>
            <Typography variant="subtitle2">Terms/Conditions</Typography>
            <div
              className="MuiTypography-subtitle2"
              dangerouslySetInnerHTML={{ __html: htmlConditions }}
            />
          </div>
        )}
        <div className={classes.grow} />
        <div className={classes.header} onClick={whatis}>
          <Typography variant="subtitle2">Powered By:</Typography>
          <img src={BlueLogo} className={classes.logo} alt="Logo" />
        </div>
        <div>
          <Link href="/privacy-statement" target="_blank">
            Privacy Statement
          </Link>
        </div>

      </div>
    </Drawer>
  );
};

SEDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  conditions: undefined,
};

SEDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  conditions: PropTypes.array,
};

export default SEDrawer;
