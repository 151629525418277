import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import QRCode from 'qrcode.react';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '20px 20px 0 0',
  },
  content: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: 'center',
  },
  pill: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 75,
    height: 7,
    borderRadius: 20,
    backgroundColor: '#9E9E9E',
  },
  qrWrapper: {
    marginTop: 48,
    marginBottom: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const QRSheet = ({ open, handleClose, link }) => {
  const classes = useStyles();

  return (
    <Drawer
      PaperProps={{
        classes: {
          root: classes.root,
        },
      }}
      open={open}
      onClose={handleClose}
      anchor="bottom"
    >
      <div className={classes.content}>
        <div className={classes.pill} />
        <div className={classes.qrWrapper}>
          <QRCode id="qr-code-id" value={link} size={175} />
        </div>
      </div>
    </Drawer>
  );
};

QRSheet.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default QRSheet;
