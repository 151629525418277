import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { Box } from '@material-ui/core';
import Colors from '../../../constants/colors';
import SEEventBottomSheet from '../EventBottomSheet';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  ctaButton: {
    borderRadius: 25,
    height: 26,
  },
  buttonText: {
    fontSize: 10,
  },
  calendarText: {
    fontSize: 10,
    color: Colors.primary,
  },
  imageRow: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    paddingLeft: 24,
  },
  image: {
    width: '100%',
    height: 150,
    objectFit: 'cover',
    borderRadius: 10,
  },
  imageContainer: {
    width: '80%',
    minWidth: '80%',
    marginRight: 24,
  },
  ctaRow: {
    display: 'flex',
    width: '100%',
    paddingLeft: 24,
    marginTop: 10,
    marginBottom: 24,
  },
}));

function ImageCarousel({ data, handleConversions }) {
  const classes = useStyles();
  const [showEventSheet, setShowEventSheet] = useState(false);

  const handleCloseEventSheet = () => {
    setShowEventSheet(false);
  };

  const handleActionOne = async () => {
    await handleConversions({
      type: 'carousel',
      action: 1,
    });
    switch (data.ctaOne.type) {
      case 'redirect': {
        const link = data.ctaOne.link.includes('https://')
          ? data.ctaOne.link
          : `https://${data.ctaOne.link}`;
        const win = window.open(link, '_self');
        win.focus();
        break;
      }
      case 'event':
        setShowEventSheet(true);
        break;
      default:
        break;
    }
  };

  const handleActionTwo = async () => {
    await handleConversions({
      type: 'carousel',
      action: 2,
    });
    switch (data.ctaTwo.type) {
      case 'redirect': {
        const link = data.ctaTwo.link.includes('https://')
          ? data.ctaTwo.link
          : `https://${data.ctaTwo.link}`;
        const win = window.open(link, '_self');
        win.focus();
        break;
      }
      case 'event':
        setShowEventSheet(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.imageRow}
        style={{ borderRadius: 4 * data?.styling?.cardBorder }}
      >
        {data.images !== null && data.images.length > 0
          ? data.images.map((image) => (
              <div className={classes.imageContainer} key={image?.image?.url}>
                <img
                  className={classes.image}
                  src={image?.image?.url}
                  alt="Background"
                />
                <Box
                  color={data.styling?.mainColor}
                  textAlign={data.styling?.mainAlignment}
                  fontSize={data.styling?.mainSize}
                  fontStyle={
                    data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
                  }
                  style={{
                    textDecoration: data.styling?.mainFormat?.includes(
                      'underline',
                    )
                      ? 'underline'
                      : '',
                  }}
                  fontWeight={
                    data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
                  }
                >
                  <Typography variant="subtitle1">
                    {image.description}
                  </Typography>
                </Box>
              </div>
            ))
          : null}
      </div>
      <div className={classes.ctaRow}>
        {data.ctaOne.type === 'redirect' ? (
          <Button
            color="primary"
            variant={data.styling?.buttonVariant}
            style={{
              borderRadius: 4 * data.styling?.buttonBorder,
              borderColor:
                data.styling?.buttonVariant === 'outlined'
                  ? data.styling?.buttonColor
                  : '',
              backgroundColor:
                data.styling?.buttonVariant === 'contained'
                  ? data.styling?.buttonColor
                  : '',
            }}
            className={classes.ctaButton}
            disableElevation
            onClick={handleActionOne}
          >
            <Typography className={classes.buttonText}>
              {data.ctaOne.name}
            </Typography>
          </Button>
        ) : null}

        {data.ctaOne.type === 'event' ? (
          <Button
            color="primary"
            className={classes.ctaButton}
            disableElevation
            onClick={handleActionOne}
            endIcon={<EventOutlinedIcon />}
          >
            <Typography variant="caption">Add to Calendar</Typography>
          </Button>
        ) : null}

        {data.ctaTwo.type === 'redirect' ? (
          <Button
            color="primary"
            variant={data.styling?.buttonVariant}
            style={{
              borderRadius: 4 * data.styling?.buttonBorder,
              borderColor:
                data.styling?.buttonVariant === 'outlined'
                  ? data.styling?.buttonColor
                  : '',
              backgroundColor:
                data.styling?.buttonVariant === 'contained'
                  ? data.styling?.buttonColor
                  : '',
            }}
            className={classes.ctaButton}
            disableElevation
            onClick={handleActionTwo}
          >
            <Typography className={classes.buttonText}>
              {data.ctaTwo.name}
            </Typography>
          </Button>
        ) : null}

        {data.ctaTwo.type === 'event' ? (
          <Button
            color="primary"
            className={classes.ctaButton}
            disableElevation
            onClick={handleActionTwo}
            endIcon={<EventOutlinedIcon />}
          >
            <Typography className={classes.calendarText}>
              Add to Calendar
            </Typography>
          </Button>
        ) : null}
      </div>
      <SEEventBottomSheet
        open={showEventSheet}
        handleClose={handleCloseEventSheet}
      />
    </div>
  );
}

ImageCarousel.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default ImageCarousel;
