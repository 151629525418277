import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '../../../assets/icons/menu.svg';
import Colors from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: Colors.white,
    paddingTop: 5,
    paddingBottom: 5,
    height: 80,
  },
  anchor: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
  },
  toggleButton: {
    marginLeft: 'auto',
  },
  title: {
    color: Colors.font,
    flexGrow: 1,
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    height: 50,
    marginRight: 8,
  },
}));

function SEAppBar({ photoUrl, name, handleOpenDrawer, redirectUrl }) {
  const classes = useStyles();

  const BusinessInfo = (
    <>
      {photoUrl && photoUrl !== null && typeof photoUrl !== 'undefined' ? (
        <img className={classes.image} src={photoUrl} alt="Location" />
      ) : null}
    </>
  );

  const formattedUrl =
    redirectUrl &&
    (redirectUrl.includes('https://') || redirectUrl.includes('http://'))
      ? redirectUrl
      : `https://${redirectUrl}`;

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {redirectUrl ? (
            <a
              href={formattedUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.anchor}
            >
              {BusinessInfo}
            </a>
          ) : (
            BusinessInfo
          )}
          <Typography variant="body1" className={classes.title}>
            {name}
          </Typography>
          <IconButton
            aria-label="drawer"
            onClick={handleOpenDrawer}
            className={classes.toggleButton}
          >
            <img src={MenuIcon} alt="Icon" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

SEAppBar.defaultProps = {
  redirectUrl: '',
  photoUrl: null,
};

SEAppBar.propTypes = {
  name: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  handleOpenDrawer: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
};

export default SEAppBar;
