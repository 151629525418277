import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import SEEventBottomSheet from '../EventBottomSheet';
import ShareSheet from '../ShareSheet';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 200,
    paddingRight: 200,
  },
}));

const PremiumButton = ({ data, handleConversions, title, description }) => {
  const classes = useStyles();
  const [showEventSheet, setShowEventSheet] = useState(false);
  const [showShareSheet, setShowShareSheet] = useState(false);
  const [buttonEvent, setButtonEvent] = useState(null);

  const handleClick = async () => {
    await handleConversions({
      type: 'button',
      action: 1,
    });

    if (data.version && data.version === 2) {
      switch (data.cta.type) {
        case 'redirect': {
          const link = data.cta.link.includes('https://')
            ? data.cta.link
            : `https://${data.cta.link}`;
          const win = window.open(link, '_self');
          win.focus();
          break;
        }
        case 'event':
          setButtonEvent({
            startTime: data.cta.startDate,
            endTime: data.cta.endDate,
            location: data.cta.address,
            title,
            description,
          });
          setShowEventSheet(true);
          break;
        case 'share':
          setShowShareSheet(true);
          break;
        default:
          break;
      }
    } else {
      const win = window.open(data.link, '_self');
      win.focus();
    }
  };

  const renderText = () => {
    if (data.version && data.version === 2) {
      switch (data.cta.type) {
        case 'redirect':
          return data.cta.name;
        case 'event':
          return 'Add to Calendar';
        case 'share':
          return 'Share';
        default:
          return 'CTA';
      }
    }
    return data.cta;
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        style={{
          borderRadius: 4 * data.styling?.buttonBorder,
          backgroundColor: data.styling?.buttonColor,
          height: 50,
        }}
        fullWidth
        onClick={handleClick}
      >
        {renderText()}
      </Button>
      <SEEventBottomSheet
        open={showEventSheet}
        handleClose={() => setShowEventSheet(false)}
        event={buttonEvent}
      />
      <ShareSheet
        open={showShareSheet}
        handleClose={() => setShowShareSheet(false)}
        link={window.location.href}
        title={title}
        details={description}
      />
    </div>
  );
};

PremiumButton.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default PremiumButton;
