import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse/node';
import {
  Switch,
  Route,
  StaticRouter,
  BrowserRouter,
  Redirect,
} from 'react-router-dom';
// import { createTheme } from '@material-ui/styles';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/styles';
import Colors from './constants/colors';
import PageNotFound from './views/404';
import Home from './views/home';
import Privacy from './views/privacy';
import Premium from './views/premium';
import BusinessCard from './views/business_card';
import Standard from './views/standard';
import Sample from './views/sample';
import config from './config';
import PrivacyStatement from './views/privacyAndPolicy';

Parse.initialize(config.PARSE_APP_ID, '');
Parse.serverURL = config.PARSE_URL;

const theme = createTheme({
  MuiCssBaseline: {
    styleOverrides: `
      * {
        font-family: Arial, Helvetica, sans-serif;
      }
    `,
  },
  palette: {
    text: {
      primary: Colors.font,
    },
    primary: {
      main: Colors.primary,
    },
  },
});

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/404" render={() => <PageNotFound />} />
    <Route exact path="/privacy" render={() => <Privacy />} />
    <Route
      exact
      path="/standard/:campaignId"
      render={({ match }) => <Standard couponId={match.params.campaignId} />}
    />
    <Route
      exact
      path="/premium/:campaignId"
      render={({ match }) => {
        const query = match.params.campaignId;
        const results = query.split('&');

        const couponId = results[0];
        let preview = false;

        if (results.length > 1) {
          const secondParam = results[1];
          if (secondParam.length >= 12 && secondParam === 'preview=true') {
            preview = true;
          }
        }

        return <Premium couponId={couponId} preview={preview} />;
      }}
    />
    <Route
      exact
      path="/sample/:campaignId"
      render={({ match }) => <Sample campaignId={match.params.campaignId} />}
    />
    <Route
      exact
      path="/businesscard/:cardId"
      render={({ match }) => <BusinessCard cardId={match.params.cardId} />}
    />
    <Route
      exact
      path="/privacy-statement"
      render={() => <PrivacyStatement />}
    />
    <Redirect to="/404" />
  </Switch>
);

const App = ({ location }) => {
  if (location) {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <StaticRouter location={location} context={{}}>
            <AppRoutes />
          </StaticRouter>
        </div>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

App.defaultProps = {
  location: null,
};

App.propTypes = {
  location: PropTypes.string,
};

export default App;
