import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import StandardDesktop from './StandardDesktop';
import StandardMobile from './StandardMobile';
import Colors from '../../constants/colors';
import BlueLogo from '../../assets/images/icon_blue.png';
import {
  getStandardCampaign,
  setStandardConversions,
  setStandardMetrics,
} from '../../redux/actions/standard_actions';
import useTappShareCookie from '../../hooks/cookie_hooks';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const Standard = ({ couponId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookie = useTappShareCookie();

  const { campaign, business } = useSelector((state) => state.standardState);

  useEffect(() => {
    dispatch(getStandardCampaign({ id: couponId }));
  }, []);

  useEffect(() => {
    if (cookie) {
      dispatch(setStandardMetrics({ couponId, cookieId: cookie }));
    }
  }, [cookie]);

  const handleConversion = () => {
    dispatch(setStandardConversions({ couponId, cookieId: cookie }));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {campaign !== null && typeof campaign !== 'undefined' ? (
        <Helmet>
          <title>{campaign.title}</title>
          <meta property="og:title" content={campaign.title} />
          <meta property="og:description" content={campaign.filteredDetails} />
          <meta property="og:image" content={campaign.photoUrl} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Campaign Not Found</title>
          <meta property="og:title" content="Campaign Not Found" />
          <meta
            property="og:description"
            content="Check out TappShare. Easily distribute campaigns."
          />
          <meta property="og:image" content={BlueLogo} />
        </Helmet>
      )}
      <Hidden mdUp>
        <StandardMobile
          campaign={campaign}
          business={business}
          handleConversions={handleConversion}
        />
      </Hidden>
      <Hidden smDown>
        <StandardDesktop
          campaign={campaign}
          business={business}
          handleConversions={handleConversion}
        />
      </Hidden>
    </div>
  );
};

Standard.serverFetch = getStandardCampaign;

Standard.propTypes = {
  couponId: PropTypes.string.isRequired,
};

export default Standard;
