/* eslint-disable no-nested-ternary */
const dev = {
  FRONT_END_URL: 'https://dev.justforyou.fyi',
};
const staging = {
  FRONT_END_URL: 'https://justforyou.fyi',
};
const prod = {
  FRONT_END_URL: 'https://justforyou.fyi',
};
const configUrl =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : prod;
export default {
  ...configUrl,
};
