import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_SAMPLE_CAMPAIGN } from '../constants/sample_constants';
import { setSampleCampaign } from '../actions/sample_actions';
import { parseGetSampleCampaign } from '../queries/sample_queries';

function* getCampaign({ payload: { id } }) {
  try {
    const campaign = yield call(parseGetSampleCampaign, { id });
    if (!campaign) {
      throw new Error({ code: 404, message: 'Sample Campaign Not Found' });
    }
    yield put(setSampleCampaign({ campaign }));
  } catch (e) {
    console.error(`Error fetching sample campaign`);
    yield put(setSampleCampaign({ campaign: null }));
    // TODO: Navigate to 404 Not found
  }
}

function* sampleSaga() {
  yield takeLatest(GET_SAMPLE_CAMPAIGN, getCampaign);
}

export default sampleSaga;
