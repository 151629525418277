import * as types from '../constants/standard_constants';

const INITIAL_STATE = {
  campaign: null,
  business: null,
};

const setCampaign = (state, action) => ({
  ...state,
  campaign: action.payload.campaign,
  business: action.payload.business,
});

function standardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_STANDARD_CAMPAIGN: {
      return setCampaign(state, action);
    }
    default: {
      return state;
    }
  }
}

export default standardReducer;
