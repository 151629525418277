import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SEAppBar from '../../components/desktop/AppBar';
import SEDrawer from '../../components/desktop/Drawer';
import Colors from '../../constants/colors';
import BlueIcon from '../../assets/images/icon_blue.png';
import BackgroundImage from '../../assets/images/default-background.svg';
import config from '../../config';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    paddingLeft: 200,
    paddingRight: 200,
    paddingBottom: 24,
    paddingTop: 104,
    overflow: 'auto',
  },
  cardImage: {
    width: 350,
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px 0 0 10px',
    padding: '0 !important',
  },
  cardImageTwo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '10px 0 0 10px',
    padding: '0 !important',
  },
  cardContent: {
    flexGrow: 1,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: 150,
    float: 'right',
  },
  paper: {
    borderRadius: 10,
    marginBottom: 24,
    padding: '0 !important',
    height: 250,
    display: 'flex',
  },
}));

const HomeDesktop = () => {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className={classes.root}>
      <div>
        <SEAppBar
          handleOpenDrawer={handleDrawerOpen}
          name="TappShare"
          photoUrl={BlueIcon}
        />
      </div>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <img
            src={BackgroundImage}
            className={classes.cardImage}
            alt="Background"
          />
          <div className={classes.cardContent}>
            <Typography variant="h6" component="h2">
              Create Campaigns
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ marginTop: 10, flexGrow: 1 }}
            >
              Do you want to create campaigns? Easily share content, calendar
              invites, or even coupons through all messaging channels.
            </Typography>
            <Button
              href={`${config.MARKETING_URL}/pricing/`}
              size="small"
              color="primary"
              className={classes.button}
            >
              Learn More
            </Button>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <div style={{ padding: 50, width: 350 }}>
            <img
              src={BlueIcon}
              className={classes.cardImageTwo}
              alt="Background"
            />
          </div>

          <div className={classes.cardContent}>
            <Typography variant="h6" component="h2">
              What is TappShare
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ marginTop: 10, flexGrow: 1 }}
            >
              TappShare is the premier digital word-of-mouth messaging platform
              for team syndicated content.
            </Typography>
            <Button
              href={config.MARKETING_URL}
              size="small"
              color="primary"
              className={classes.button}
            >
              Learn More
            </Button>
          </div>
        </Paper>
      </div>
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

export default HomeDesktop;
