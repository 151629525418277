import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Colors from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 24,
    paddingBottom: 24,
  },
  button: {
    width: 350,
    height: 60,
    borderRadius: 50,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
}));

function SECTAButton({ handleClick, text }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={2}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClick}
      >
        {text}
      </Button>
    </Paper>
  );
}

SECTAButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SECTAButton;
