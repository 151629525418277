import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { saveAs } from 'file-saver';
import Colors from '../../constants/colors';
import BlueLogo from '../../assets/images/icon_blue.png';
import BusinessCardMobile from './BusinessCardMobile';
import BusinessCardDesktop from './BusinessCardDesktop';

import { getBusinessCard } from '../../redux/actions/business_card_actions';
import { getVCards } from '../../redux/queries/business_card_queries';
import configUrl from '../../config-url';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const BusinessCard = ({ cardId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // remove the line below
  // const cookie = useTappShareCookie();

  const { businessCard } = useSelector((state) => state.businessCardState);

  useEffect(() => {
    if (cardId !== null) {
      dispatch(getBusinessCard({ id: cardId }));
    }
  }, []);

  const downloadVCard = async () => {
    const vcard = await getVCards({
      firstName: businessCard.user.firstName,
      lastName: businessCard.user.lastName,
      cellPhone: businessCard.user.mobilePhone,
      workPhone: businessCard.user.businessPhone,
      businessName: businessCard.business.name,
      position: businessCard.user.position,
      note: businessCard.user.about,
      workEmail: businessCard.user.email,
      homeEmail: businessCard.user.secondaryEmail,
      photo: businessCard.user.photo,
      twitter: businessCard.socials.twitter,
      facebook: businessCard.socials.facebook,
      instagram: businessCard.socials.instagram,
      linkedin: businessCard.socials.linkedin,
      whatsapp: businessCard.socials.whatsapp,
      telegram: businessCard.socials.telegram,
    });
    if (vcard) {
      const blob = new Blob([vcard], { type: 'text/vcard' });
      saveAs(blob, 'contact.vcf');
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {businessCard &&
      businessCard.user !== null &&
      typeof businessCard.user !== 'undefined' ? (
        <div>
          <Helmet>
            <title>{businessCard.user.name}</title>
            <meta property="og:title" content={businessCard.user.name} />
            <meta property="og:description" content="Business Card" />
            <meta
              property="og:image"
              itemProp="image"
              content={businessCard.user.photo}
            />
            <meta
              property="og:image:secure_url"
              itemProp="image"
              content={businessCard.user.photo}
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
              property="og:url"
              content={`${configUrl.FRONT_END_URL}/businesscard/${cardId}`}
            />
            <meta property="og:type" content="website" />
          </Helmet>
          <Hidden mdUp>
            <BusinessCardMobile
              cardId={cardId}
              user={businessCard.user}
              business={businessCard.business}
              socials={businessCard.socials}
              downloadContact={() => downloadVCard()}
            />
          </Hidden>
          <Hidden smDown>
            <BusinessCardDesktop
              cardId={cardId}
              user={businessCard.user}
              business={businessCard.business}
              socials={businessCard.socials}
              downloadContact={() => downloadVCard()}
            />
          </Hidden>
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Business Card Not Found</title>
            <meta property="og:title" content="Not Found" />
            <meta
              property="og:description"
              content="Check out TappShare. Easily distribute campaigns."
            />
            <meta property="og:image" content={BlueLogo} />
          </Helmet>
          <div>Not found: Todo</div>
        </div>
      )}
    </div>
  );
};

BusinessCard.serverFetch = getBusinessCard;

BusinessCard.propTypes = {
  cardId: PropTypes.string.isRequired,
};

export default BusinessCard;
