import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Colors from '../../../constants/colors';
import Age from '../../../assets/icons/age.svg';
import Calendar from '../../../assets/icons/calendar.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 8,
    position: 'sticky',
    top: 0,
    marginTop: -120,
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginLeft: -4,
  },
  text: {
    color: Colors.white,
  },
  headerText: {
    height: 62,
    color: Colors.white,
    textOverflow: 'ellipsis',
  },
  icon: {
    color: Colors.white,
    width: 30,
    height: 30,
  },
}));

function SEHeader({ title, endDate, age }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.headerText}>
        {title}
      </Typography>
      <div className={classes.row}>
        <img className={classes.icon} src={Calendar} alt="Expiration" />
        <Typography
          className={classes.text}
          variant="caption"
          style={{ marginLeft: 4, marginRight: 20 }}
        >
          {`Expires: ${Moment(endDate).format('MMM D, YYYY')}`}
        </Typography>
        <img className={classes.icon} src={Age} alt="Age limit" />
        <Typography
          className={classes.text}
          variant="caption"
          style={{ marginLeft: 4 }}
        >
          {age}
        </Typography>
      </div>
    </div>
  );
}

SEHeader.propTypes = {
  title: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
};

export default SEHeader;
