import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import SampleDesktop from './SampleDesktop';
import SampleMobile from './SampleMobile';
import Colors from '../../constants/colors';
import BlueLogo from '../../assets/images/icon_blue.png';
import { getSampleCampaign } from '../../redux/actions/sample_actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const Sample = ({ campaignId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { campaign } = useSelector((state) => state.sampleState);

  useEffect(() => {
    dispatch(getSampleCampaign({ id: campaignId }));
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Helmet>
        <title>Sample Campaign</title>
        <meta property="og:title" content="Sample Campaign" />
        <meta
          property="og:description"
          content="Test how TappShare works. Quick and fast information delivered right where your audience is."
        />
        <meta property="og:image" content={BlueLogo} />
      </Helmet>
      <Hidden mdUp>
        <SampleMobile data={campaign} />
      </Hidden>
      <Hidden smDown>
        <SampleDesktop data={campaign} />
      </Hidden>
    </div>
  );
};

Sample.serverFetch = getSampleCampaign;

Sample.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default Sample;
