export const CollectedInformation = [
  {
    title: 'Usage, System, and Log Data.',
    content:
      ' Your IP address, device identifier, location information, MAC address, browser, and activity on the Service, including, but not limited to, date and times of use, clicks, page and whether recipients of Services Content open the Services Content, and the amount of time you spend on each page.',
  },
  {
    title: 'Data Collected Through Cookies and Similar Technologies.',
    content:
      ' Like many online services, we use various technologies to collect usage data and store preferences. One such technology is called a cookie. A cookie is a small data file that is transferred to your computer’s hard disk. We may use both session cookies and persistent cookies to better understand how you interact with the Services and to monitor aggregate usage by our users and web traffic routing on the Services.  Also, we may create a unique device or user ID for you and store it in a cookie so we can recognize you and customize your experience based on your preferences. Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies or to prompt you before accepting such a cookie. Please note that if you choose to remove or reject browser cookies, this could affect the availability or functionality of the Services.  We may also use web beacons, clear GIFs, or 1×1 pixels as part of the Services.',
  },
];

export const UsingInformation = [
  {
    content: 'Provide, operate, maintain and improve the Service;',
  },
  {
    content:
      'Respond to your comments, questions, and requests and to provide customer service and support;',
  },
  {
    content: 'Conduct research;',
  },
  {
    content: 'Prevent potentially illegal activities;',
  },
  {
    content: 'Allow you to redeem Services Content;',
  },
  {
    content: 'Fulfill your requests through the Services;',
  },
  {
    content: 'Send you promotional materials and information;',
  },
  {
    content:
      'Monitor and analyze trends, usage, and activities in connection with the Services and for marketing or advertising purposes; and',
  },
  {
    content: 'For other purposes about which we notify you.',
  },
];

export const SharingInformation = [
  {
    content:
      'Service Providers.  We may share your Personally Identifying Information with third-party vendors, consultants, and other service provides who are working on behalf of Company and require access to your Personally Identifying Information to carry out that work.',
  },
  {
    content:
      'Compliance with Laws.  We may disclose your Personally Identifying Information to a third party if (1) we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request, (2) to enforce our agreements, policies, or Terms of Use, and (3) to respond to an emergency which we believe in good faith requires us to disclose such information to assist in preventing the death or serious bodily injury of any person.',
  },
  {
    content:
      'Recipients of Message Content.  We may share Message Content with the person that you designate to receive such Message Content via text message.',
  },
  {
    content:
      'Business Transfers.  We may share or transfer your Personally Identifying Information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.',
  },
  {
    content:
      'Affiliates.  We may share your Personally Identifying Information with affiliates of Company for use as otherwise described in this Privacy Policy.',
  },
];
