import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Moment from 'moment';
import Colors from '../../constants/colors';
import SEAppBar from '../../components/desktop/AppBar';
import SEDrawer from '../../components/desktop/Drawer';
import SEHeroImage from '../../components/desktop/HeroImage';
import StandardContent from '../../components/desktop/StandardContent';
import StandardAction from '../../components/desktop/StandardAction';
import SEHeader from '../../components/desktop/Header';
import SENotFound from '../../components/desktop/NotFound';
import SEExpired from '../../components/desktop/Expired';
import SETerminated from '../../components/desktop/Terminated';
import BlueIcon from '../../assets/images/icon_blue.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
  content: {
    flexGrow: 1,
  },
}));

const StandardDesktop = ({ campaign, business, handleConversions }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleDrawerOpen = () => {
    if (openDrawer) {
      setOpenDrawer(false);
    } else {
      setOpenDrawer(true);
    }
  };

  const Content = () => {
    if (campaign === null || campaign === 'undefined') {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name="TappShare"
              photoUrl={BlueIcon}
            />
          </div>
          <SENotFound message="Campaign Not Found" />
        </div>
      );
    }
    if (campaign.terminated) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={business.name}
              photoUrl={business.photoUrl}
            />
          </div>
          <SETerminated message={campaign.terminationReason} />
        </div>
      );
    }
    if (Moment().isAfter(Moment(campaign.endDate))) {
      return (
        <div className={classes.root}>
          <div>
            <SEAppBar
              handleOpenDrawer={handleDrawerOpen}
              name={business.name}
              photoUrl={business.photoUrl}
            />
          </div>
          <SEExpired />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div>
          <SEAppBar
            handleOpenDrawer={handleDrawerOpen}
            name={business.name}
            photoUrl={business.photoUrl}
          />
        </div>
        <div className={classes.content}>
          <SEHeroImage photoUrl={campaign.photoUrl} />
          <SEHeader
            title={campaign.title}
            age={campaign.age}
            endDate={campaign.endDate}
          />
          <StandardContent
            description={campaign.description}
            conditions={campaign.conditions}
          />
        </div>
        <StandardAction
          type={campaign.type}
          action={campaign.action}
          onConversion={() => handleConversions()}
          title={campaign.title}
          description={campaign.filteredDetails}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Content />
      <SEDrawer open={openDrawer} handleClose={handleDrawerClose} />
    </div>
  );
};

StandardDesktop.propTypes = {
  campaign: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default StandardDesktop;
