import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Snackbar from '@material-ui/core/Snackbar';
import copy from 'copy-to-clipboard';
import FacebookIcon from '../../../assets/icons/facebook.svg';
import EmailIcon from '../../../assets/icons/email_icon.svg';
import LinkIcon from '../../../assets/icons/link_icon.svg';
import SMSIcon from '../../../assets/icons/sms_icon.svg';
import WhatsAppIcon from '../../../assets/icons/whatsapp_icon.svg';
import Colors from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '20px 20px 0 0',
  },
  content: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: 'center',
  },
  pill: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 75,
    height: 7,
    borderRadius: 20,
    backgroundColor: '#9E9E9E',
  },
  header: {
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: 'center',
    marginBottom: 16,
  },
  cancelButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
    marginBottom: 16,
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  snackbar: {
    backgroundColor: Colors.success,
  },
}));

function ShareSheet({ open, handleClose, link, title, details }) {
  const classes = useStyles();
  const [showSnack, setShowSnack] = useState(false);

  const copyLink = () => {
    copy(link);
    setShowSnack(true);
  };

  const handleSMS = () => {
    const win = window.open(
      `sms:&body=Check%20This%20Out&body=${link}`,
      '_self',
    );
    win.focus();
  };

  const handleWhatsApp = () => {
    const win = window.open(
      `https://api.whatsapp.com/send?text=${link}`,
      '_blank',
    );
    win.focus();
  };

  const handleEmail = () => {
    const win = window.open(`mailto:?subject=Check%20This%20Out&body=${link}`);
    win.focus();
  };

  const handleFacebook = () => {
    const win = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${link}`,
      '_blank',
    );
    win.focus();
  };

  const handleMore = () => {
    navigator.share({
      title,
      text: details,
      url: link,
    });
  };

  const handleSnackClose = () => {
    setShowSnack(false);
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          classes: {
            root: classes.root,
          },
        }}
        open={open}
        onClose={handleClose}
        anchor="bottom"
      >
        <div className={classes.content}>
          <div className={classes.pill} />
          <Typography variant="h6" className={classes.header}>
            Share this campaign with friends
          </Typography>
          <List>
            <ListItem button className={classes.listItem} onClick={copyLink}>
              <div style={{ flexGrow: 1 }}>
                <Typography>Copy link</Typography>
              </div>
              <img src={LinkIcon} alt="Icon" />
            </ListItem>
            {navigator.share ? (
              <ListItem button className={classes.listItem} onClick={handleSMS}>
                <div style={{ flexGrow: 1 }}>
                  <Typography>SMS</Typography>
                </div>
                <img src={SMSIcon} alt="Icon" />
              </ListItem>
            ) : null}
            <ListItem
              button
              className={classes.listItem}
              onClick={handleWhatsApp}
            >
              <div style={{ flexGrow: 1 }}>
                <Typography>WhatsApp</Typography>
              </div>
              <img src={WhatsAppIcon} alt="Icon" />
            </ListItem>
            <ListItem button className={classes.listItem} onClick={handleEmail}>
              <div style={{ flexGrow: 1 }}>
                <Typography>Email</Typography>
              </div>
              <img src={EmailIcon} alt="Icon" />
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              onClick={handleFacebook}
            >
              <div style={{ flexGrow: 1 }}>
                <Typography>Facebook</Typography>
              </div>
              <img src={FacebookIcon} alt="Icon" />
            </ListItem>
            {navigator.share ? (
              <ListItem
                button
                className={classes.listItem}
                onClick={handleMore}
              >
                <div style={{ flexGrow: 1 }}>
                  <Typography>More</Typography>
                </div>
                <img src={LinkIcon} alt="Icon" />
              </ListItem>
            ) : null}
          </List>
          <Button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        ContentProps={{
          classes: {
            root: classes.snackbar,
          },
        }}
        open={showSnack}
        onClose={handleSnackClose}
        message="Link Copied"
        autoHideDuration={500}
      />
    </div>
  );
}

ShareSheet.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};

export default ShareSheet;
