import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import Box from '@material-ui/core/Box';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Colors from '../../../constants/colors';
import SEEventBottomSheet from '../EventBottomSheet';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 200,
    paddingRight: 200,
  },
  description: {
    height: 'fit-content',
    backgroundColor: 'inherit',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: 450,
    objectFit: 'cover',
  },
  title: {
    position: 'absolute',
    bottom: 0,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 16,
  },
  titleText: {
    color: Colors.white,
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    flexGrow: 1,
  },
  actionArea: {
    padding: 0,
    marginBottom: 16,
    display: 'flex',
  },
  ctaButton: {
    height: 35,
    marginLeft: 24,
    marginRight: 24,
  },
  buttonText: {
    fontSize: 12,
  },
  calendarText: {
    fontSize: 12,
    color: Colors.primary,
  },
}));

function InfoCard({ data, handleConversions, title, description }) {
  const classes = useStyles();
  const [showEventSheet, setShowEventSheet] = useState(false);
  const [descriptionState, setDescriptionState] = useState('');
  const [isRichText, setIsRichText] = useState(false);
  const [buttonEvent, setButtonEvent] = useState(null);

  useEffect(() => {
    if (data.description !== null && typeof data.description !== 'object') {
      setDescriptionState(data.description);
    } else if (data.description.ops) {
      const cfg = {};
      const converter = new QuillDeltaToHtmlConverter(
        data.description.ops,
        cfg,
      );
      setDescriptionState(converter.convert());
      setIsRichText(true);
    }
  }, []);

  const handleCloseEventSheet = () => {
    setShowEventSheet(false);
  };

  const handleActionOne = async () => {
    await handleConversions({
      type: 'info',
      action: 1,
    });

    switch (data.ctaOne.type) {
      case 'redirect': {
        const link = data.ctaOne.link.includes('https://')
          ? data.ctaOne.link
          : `https://${data.ctaOne.link}`;
        const win = window.open(link, '_blank');
        win.focus();
        break;
      }
      case 'event':
        setButtonEvent({
          startTime: data.ctaOne.startDate,
          endTime: data.ctaOne.endDate,
          location: data.ctaOne.address,
          title,
          description,
        });
        setShowEventSheet(true);
        break;
      default:
        break;
    }
  };

  const handleActionTwo = async () => {
    await handleConversions({
      type: 'info',
      action: 2,
    });

    switch (data.ctaTwo.type) {
      case 'redirect': {
        const link = data.ctaTwo.link.includes('https://')
          ? data.ctaTwo.link
          : `https://${data.ctaTwo.link}`;
        const win = window.open(link, '_blank');
        win.focus();
        break;
      }
      case 'event':
        setButtonEvent({
          startTime: data.ctaTwo.startDate,
          endTime: data.ctaTwo.endDate,
          location: data.ctaTwo.address,
          title,
          description,
        });
        setShowEventSheet(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Card
        className={classes.card}
        style={{ borderRadius: 4 * data?.styling?.cardBorder }}
      >
        <img src={data?.image?.url} className={classes.image} alt="Card" />
        <CardContent className={classes.content}>
          {isRichText ? (
            <Box
              id="desc-box"
              color={data.styling?.mainColor}
              textAlign={data.styling?.mainAlignment}
              fontSize={data.styling?.mainSize}
              fontStyle={
                data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
              }
              style={{
                textDecoration: data.styling?.mainFormat?.includes('underline')
                  ? 'underline'
                  : '',
              }}
              fontWeight={
                data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
              }
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: descriptionState }}
            />
          ) : (
            <Typography variant="body1">{descriptionState}</Typography>
          )}
        </CardContent>
        <CardActions className={classes.actionArea}>
          <div style={{ flexGrow: 1 }}>
            {data.ctaOne.type === 'redirect' && (
              <Button
                color="primary"
                variant={data.ctaOne.styling?.buttonVariant}
                style={{
                  borderRadius: 4 * data.ctaOne.styling?.buttonBorder,
                  borderColor:
                    data.ctaOne.styling?.buttonVariant === 'outlined'
                      ? data.ctaOne.styling?.buttonColor
                      : '',
                  backgroundColor:
                    data.ctaOne.styling?.buttonVariant === 'contained'
                      ? data.ctaOne.styling?.buttonColor
                      : '',
                }}
                className={classes.ctaButton}
                disableElevation
                onClick={handleActionOne}
              >
                <Typography className={classes.buttonText}>
                  {data.ctaOne.name}
                </Typography>
              </Button>
            )}

            {data.ctaOne.type === 'event' ? (
              <Button
                color="primary"
                className={classes.ctaButton}
                disableElevation
                onClick={handleActionOne}
                endIcon={<EventOutlinedIcon />}
              >
                <Typography variant="caption" className={classes.calendarText}>
                  Add to Calendar
                </Typography>
              </Button>
            ) : (
              <div />
            )}
          </div>

          <div>
            {data.ctaTwo.type === 'redirect' && (
              <Button
                color="primary"
                variant={data.ctaTwo.styling?.buttonVariant}
                style={{
                  borderRadius: 4 * data.ctaTwo.styling?.buttonBorder,
                  borderColor:
                    data.ctaTwo.styling?.buttonVariant === 'outlined'
                      ? data.ctaTwo.styling?.buttonColor
                      : '',
                  backgroundColor:
                    data.ctaTwo.styling?.buttonVariant === 'contained'
                      ? data.ctaTwo.styling?.buttonColor
                      : '',
                }}
                className={classes.ctaButton}
                disableElevation
                onClick={handleActionTwo}
              >
                <Typography className={classes.buttonText}>
                  {data.ctaTwo.name}
                </Typography>
              </Button>
            )}

            {data.ctaTwo.type === 'event' ? (
              <Button
                color="primary"
                className={classes.ctaButton}
                disableElevation
                onClick={handleActionTwo}
                endIcon={<EventOutlinedIcon />}
              >
                <Typography variant="caption" className={classes.calendarText}>
                  Add to Calendar
                </Typography>
              </Button>
            ) : (
              <div />
            )}
          </div>
        </CardActions>

        <SEEventBottomSheet
          open={showEventSheet}
          handleClose={handleCloseEventSheet}
          event={buttonEvent}
        />
      </Card>
    </div>
  );
}

InfoCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoCard;
