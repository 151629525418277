import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { Box } from '@material-ui/core';
import Colors from '../../../constants/colors';
import SEEventBottomSheet from '../EventBottomSheet';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 300,
    paddingLeft: 200,
    paddingRight: 200,
  },
  card: {
    width: '100%',
    height: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  imageBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(80%)',
  },
  title: {
    position: 'absolute',
    bottom: 0,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    width: '100%',
  },
  titleText: {},
  buttonText: {
    fontSize: 12,
  },
  calendarText: {
    fontSize: 12,
    color: Colors.primary,
  },
  ctaButton: {
    borderRadius: 25,
    height: 35,
    marginTop: 16,
  },
}));

function ImageCard({ data, handleConversions }) {
  const classes = useStyles();
  const [showEventSheet, setShowEventSheet] = useState(false);

  const handleCloseEventSheet = () => {
    setShowEventSheet(false);
  };

  const handleAction = async () => {
    await handleConversions({
      type: 'image',
      action: 1,
    });

    switch (data.cta.type) {
      case 'redirect': {
        const link = data.cta.link.includes('https://')
          ? data.cta.link
          : `https://${data.cta.link}`;
        const win = window.open(link, '_self');
        win.focus();
        break;
      }
      case 'event':
        setShowEventSheet(true);
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Card
        className={classes.card}
        style={{ borderRadius: 4 * data?.styling?.cardBorder }}
      >
        <div className={classes.imageBox}>
          <img
            src={data?.image?.url}
            className={classes.image}
            alt="Background"
          />
          <div className={classes.title}>
            <Box
              width="100%"
              color={data.styling?.mainColor}
              textAlign={data.styling?.mainAlignment}
              fontSize={data.styling?.mainSize}
              fontStyle={
                data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
              }
              style={{
                textDecoration: data.styling?.mainFormat?.includes('underline')
                  ? 'underline'
                  : '',
              }}
              fontWeight={
                data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
              }
            >
              {data.title !== null && data.title !== '' ? (
                <Typography variant="h4" className={classes.titleText}>
                  {data.title}
                </Typography>
              ) : null}
            </Box>

            {data.cta.type === 'redirect' && (
              <Button
                color="primary"
                variant={data.styling?.buttonVariant}
                style={{
                  borderRadius: 4 * data.styling?.buttonBorder,
                  borderColor:
                    data.styling?.buttonVariant === 'outlined'
                      ? data.styling?.buttonColor
                      : '',
                  backgroundColor:
                    data.styling?.buttonVariant === 'contained'
                      ? data.styling?.buttonColor
                      : '',
                }}
                className={classes.ctaButton}
                disableElevation
                onClick={handleAction}
              >
                <Typography className={classes.buttonText}>
                  {data.cta.name}
                </Typography>
              </Button>
            )}

            {data.cta.type === 'event' && (
              <Button
                color="primary"
                className={classes.ctaButton}
                disableElevation
                onClick={handleAction}
                endIcon={<EventOutlinedIcon />}
              >
                <Typography variant="caption" className={classes.calendarText}>
                  Add to Calendar
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </Card>
      <SEEventBottomSheet
        open={showEventSheet}
        handleClose={handleCloseEventSheet}
      />
    </div>
  );
}

ImageCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleConversions: PropTypes.func.isRequired,
};

export default ImageCard;
