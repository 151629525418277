import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import NotFoundMobile from './NotFoundMobile';
import NotFoundDesktop from './NotFoundDesktop';
import Colors from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.background,
    height: '100vh',
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp>
        <NotFoundMobile />
      </Hidden>
      <Hidden smDown>
        <NotFoundDesktop />
      </Hidden>
    </div>
  );
};

export default PageNotFound;
