import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Colors from '../../../constants/colors';
import DefaultImage from '../../../assets/images/default-background.svg';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.gray,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
  },
  custom: {
    objectFit: 'cover',
    width: '100%',
    maxHeight: 280,
  },
});

const Image = ({ image }) => {
  const classes = useStyles();

  const fetchImage = () => {
    if (!image) {
      return <img className={classes.image} src={DefaultImage} alt="Header" />;
    }
    switch (image.type) {
      case 'custom':
        return <img className={classes.custom} src={image.url} alt="Header" />;
      case 'unsplash':
        return <img className={classes.image} src={image.url} alt="Header" />;
      case 'giphy':
        return <img className={classes.custom} src={image.url} alt="Header" />;
      default:
        return (
          <img className={classes.image} src={DefaultImage} alt="Header" />
        );
    }
  };

  return <div className={classes.root}>{fetchImage()}</div>;
};

Image.propTypes = {
  image: PropTypes.object.isRequired,
};

export default Image;
