/* eslint-disable  */
import Parse from 'parse/node';

export const parseCreateCookie = async () => {
  try {
    return await Parse.Cloud.run('Redemption:createCookie');
  } catch (e) {
    console.error(`Parse Error fetching business card: ${e}`);
    return null;
  }
};
